import React from 'react';
import { makeStyles } from '@mui/styles';
import Container from '../Container.js';
import { Grid, Typography } from '@mui/material';
import StoreButton from '../../../Components/Forms/Store/StoreButton.js';
import FeaturesList from '../../../Components/Sales/FeaturesList.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  howItWorksContainer: {
    paddingBottom: '14rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      paddingBottom: 0
    }
  },
  howItWorksHeading: {
    marginBottom: '4rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '2rem'
    }
  },
  paragraph: {
    marginBottom: '3rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '2rem'
    }
  },
  howItWorksImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  button: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

const HowItWorks = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  // TODO verify this behaviour is what we want
  const handleStartClick = () => {
    const elem = document.querySelector('#hands');
    elem && elem.scrollIntoView();
  };

  return (
    <Container>
      <Grid
        container
        spacing={{ xs: 2, md: 0 }}
        className={classes.howItWorksContainer}
      >
        <Grid item xs={12} md={6}>
          <Typography
            variant='h1'
            component='h2'
            className={classes.howItWorksHeading}
          >
            {t('how-bridgebee-works')}
          </Typography>
          <Typography variant='body4' component='p' className={classes.paragraph}>
            {t('play-thousands-of-hands')}
          </Typography>
          <Typography variant='body4' component='p' className={classes.paragraph}>
            {t('our-authors-guide-you-through-each-hand')}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* TODO link to video */}
          <img
            className={classes.howItWorksImage}
            alt=''
            src='img/components/sales/portrait-iphone.jpg'
          />
        </Grid>
      </Grid>
      <FeaturesList
        features={[
          {
            heading: t('select-your-level'),
            body: t('bridgebee-grows-with-you'),
            icon: 'how-bridgebee-works-stack'
          },
          {
            heading: t('choose-your-topic'),
            body: t('need-to-work-on-defense'),
            icon: 'how-bridgebee-works-cards'
          },
          {
            heading: t('form-the-right-habits'),
            body: t('bridgebee-authors-guide-you-toward'),
            icon: 'how-bridgebee-works-logo'
          }
        ]}
      />
      <div className={classes.button}>
        <StoreButton
          onClick={handleStartClick}
          label='start-now'
        />
      </div>
    </Container>
  );
};

export default HowItWorks;
