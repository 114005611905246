import React from 'react';
import { ArticleForm } from './ArticleForm.js';
import { useParams, useNavigate } from 'react-router-dom';

const AddArticle = () => {
  const { subscriptionId, subscriptionTitle } = useParams();

  const article = {
    hands: [],
    status: "Draft",
    subscription: {},
    subscriptionId: parseInt(subscriptionId)
  };

  // Navigating back to the previous page
  const navigate = useNavigate();

  const handleSave = (upsertedArticle) => {
    // navigate(-1);
    navigate(`/admin/subscriptions/${subscriptionId}/${subscriptionTitle}/articles/${upsertedArticle.id}/${upsertedArticle.title}`);
  };

  return (
    <ArticleForm
      article={article}
      onSave={handleSave}
    />
  );
};

export default AddArticle;