import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { ReactTitle } from 'react-meta-tags';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Typography
} from '@mui/material';
import AuthorLink from './Components/AuthorLink.js';
import AuthorById from '../../../Components/Queries/AuthorById.js';
import DynamoProductSearch from '../../../Components/Queries/DynamoProductSearch.js';
import GridViewSkeleton from '../../../Components/Skeletons/GridViewSkeleton.js';
import Container from '../../../Components/Store/Container.js';
import GridView from '../../../Components/Store/GridView.js';
import BBTranslate from '../../../Components/Locale/BBTranslate.js';
import { LocaleContext } from '../../../Context';
import AuthorHeader from '../../../Components/Store/AuthorHeader.js';
import SubscriptionChip from '../../../Components/Store/Subscriptions/SubscriptionChip.js';

const useStyles = makeStyles((theme) => ({
  suitPattern: {
    left: 0
  },
  bioContainer: {
    marginTop: '4rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0
    }
  },
  linksList: {
    float: 'left',
    listStyle: 'none',
    margin: 0,
    padding: 0
  },
  linksListItem: {
    margin: 0,
    padding: 0
  },
  bio: {
    fontSize: '2rem',
    lineHeight: '2.8rem',
    paddingTop: '1rem',
    paddingRight: '12rem',
    [theme.breakpoints.down('md')]: {
      paddingRight: '8rem'
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginBottom: '3.5rem'
    }
  },
  groupHeading: {
    color: '#613344',
    margin: '5rem 0 4rem 0'
  },
  products: {
    // close up the gap between the products and the footer
    // otherwise there's a weird line of coloured background between them
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-4rem'
    }
  }
}));

const Author = () => {
  const classes = useStyles();
  const params = useParams();
  const { authorId } = params;

  const { locale } = useContext(LocaleContext);

  if (!locale) {
    return null;
  }

  const getAuthorTag = (authorName) => {
    return [[], [], [], [`${authorName}`]];
  };

  return (
    <AuthorById id={parseInt(authorId)}>
      {
        data => {
          const hasSubscription = data.products.find(p => p.type === 'Subscription');
          return (
            <div>
              <ReactTitle title={data.fullName} />
              <Container>
                <AuthorHeader
                  title={data.fullName}
                  author={data}
                  colour='#4788B1'
                >
                  {
                    hasSubscription && (
                      <SubscriptionChip />
                    )
                  }
                </AuthorHeader>
                <Grid container className={classes.bioContainer}>
                  <Grid item xs={12} sm={9} className={classes.bio}>
                    <Typography variant='body1'>
                      {data.bio}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <ul className={classes.linksList}>
                      {
                        data.authorLinks.map((link, index) => {
                          return (
                            <li key={index} className={classes.linksListItem}>
                              <AuthorLink link={link} />
                            </li>
                          );
                        })
                      }
                    </ul>
                  </Grid>
                </Grid>

                <div className={classes.products}>
                  <Typography variant='h1' className={classes.groupHeading}>
                    <BBTranslate textId={['products-by', data.fullName]} />
                  </Typography>
                  <DynamoProductSearch
                    options={{
                      orderBy: 'id',
                      // order in ascending order unless nothing ("id") is picked
                      // in which case this shows latest products first
                      orderByDirection: 'desc',
                      tags: getAuthorTag(data.fullName),
                      pagination: {
                        pageSize: 10000
                      },
                      language: data.language,
                      currency: locale.currency
                    }}
                  >
                    {({ products, loading }) => (
                      <>
                        {
                        loading
                          ? (
                            <GridViewSkeleton />
                            )
                          : (
                            <GridView
                              loading
                              products={products}
                            />
                            )
                        }
                      </>
                    )}
                  </DynamoProductSearch>
                </div>
              </Container>
            </div>
          );
        }
      }
    </AuthorById>
  );
};

export default Author;
