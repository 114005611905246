import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography
} from '@mui/material';
import BBTranslate from '../../Locale/BBTranslate.js';
import { useScreenDimensions } from '../../../Hooks';

const useStyles = makeStyles({
  label: {
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
    color: '#262626'
  },
  input: {
    backgroundColor: '#fff',
    color: '#333',
    border: '1px solid #E6E6E2',
    borderRadius: '0.2rem',
    padding: '1.2rem',
    marginTop: '0.5rem',
    fontSize: '2rem',
    height: '4.6rem'
  },
  card: {
    position: 'absolute',
    top: '3.6rem',
    right: '0.8rem',
    width: '5rem',
    textAlign: 'right',
    pointerEvents: 'none',
    '& img': {
      width: '100%'
    },
    '@media all and (max-width: 480px)': {
      height: '2.8rem'
    },
    '@media all and (max-width: 320px)': {
      display: 'none'
    }
  }
  /*
    This is causing expiry date & cvc to show in a column on mobile screens (as you would expect it to..!)
    This doesn't seem to happen on live despite the code still existing and not sure why. Keep to add back in
    if needed...
  */
  // grid: {
  //   '@media all and (max-width: 480px)': {
  //     flexDirection: 'column'
  //   }
  // }
});

// stripe elements don't allow you access to their values, all we know is if each element is valid or not
// so we can't pass the values back up to the state handler, we just let the state handler know
// when the form is valid and the when we want to create the payment method with stripe (using their lib)
// we get a reference to the stripe elements and pass that to the stripe lib method
const NewCardForm = ({ onFormComplete, onFormError, savePaymentMethod, onSavePaymentMethodChange }) => {
  const classes = useStyles();
  const [cardBrand, setCardBrand] = useState('');
  const formValidity = useRef({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false
  });
  const screenDimensions = useScreenDimensions();

  const handleFormValidityChange = (field, isFieldComplete) => {
    formValidity.current[field] = isFieldComplete;
    const { cardNumber, cardExpiry, cardCvc } = formValidity.current;
    onFormComplete(cardNumber && cardExpiry && cardCvc);
  };

  const handleBrandChange = (brand) => {
    if (brand && brand !== cardBrand) {
      setCardBrand(brand);
    }
  };

  const handleChange = (e) => {
    handleBrandChange(e.brand);
    const disableButton = !!e.error;
    if (e.complete !== undefined) {
      handleFormValidityChange(e.elementType, e.complete);
    } else if (e.error) {
      onFormError(e.error.message, disableButton);
    } else if (e.error === undefined) {
      onFormError('', disableButton);
    }
  };

  const cardNumberElementStyle = {
    base: {
      fontWeight: 'bold',
      fontFamily: 'courier',
      fontSize: '18px',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        fontStyle: 'italic',
        color: '#999'
      }
    }
  };

  const { width } = screenDimensions;
  if (width <= 540) {
    cardNumberElementStyle.base.fontSize = '14px';
  }

  return (
    <Grid item container spacing={2} className={classes.grid}>
      <Grid item xs={12}>
        <div style={{ position: 'relative' }}>
          <div className={classes.label}><BBTranslate textId='card-number' />&nbsp;*</div>
          {
            cardBrand === 'visa' || cardBrand === 'mastercard' || cardBrand === 'amex'
              ? <div className={classes.card}><img src={`/img/components/card_details/icon_${cardBrand}.png`} alt={cardBrand} /></div>
              : null
          }
          <CardNumberElement
            className={classes.input}
            style={cardNumberElementStyle}
            onChange={handleChange}
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.label}><BBTranslate textId='expiry-date' />&nbsp;*</div>
        <CardExpiryElement
          className={classes.input}
          style={cardNumberElementStyle}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <div className={classes.label}><BBTranslate textId='security-code' />&nbsp;*</div>
        <CardCvcElement
          className={classes.input}
          style={cardNumberElementStyle}
          onChange={handleChange}
        />
      </Grid>
      {
        onSavePaymentMethodChange &&
          <Grid item xs={12} className={classes.infoText}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={savePaymentMethod}
                  onChange={onSavePaymentMethodChange}
                  value='savePaymentMethod'
                  color='primary'
                />
              }
              label={
                <Typography variant='body3'>
                  <BBTranslate textId={width > 414 ? 'remember-card-details-for-future' : 'remember-card-details'} />
                </Typography>
              }
            />
          </Grid>
      }
    </Grid>
  );
};

NewCardForm.propTypes = {
  onFormComplete: PropTypes.func.isRequired,
  onFormError: PropTypes.func,
  savePaymentMethod: PropTypes.bool,
  onSavePaymentMethodChange: PropTypes.func
};

export default NewCardForm;
