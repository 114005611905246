import React from 'react';
import { adopt } from 'react-adopt';
import Authors from '../../Components/Queries/Authors.js';
import Tags from '../../Components/Queries/Tags.js';
import Prices from '../../Components/Queries/Prices.js';

const PreLoad = () => {
  const Composed = adopt({
    one: ({ render }) => <Authors>{render}</Authors>,
    two: ({ render }) => <Tags>{render}</Tags>,
    three: ({ render }) => <Prices>{render}</Prices>
  });
  return (
    <>
      <Composed>
        {() => {
          return null;
        }}
      </Composed>
    </>
  );
};

export default PreLoad;
