import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/styles';
import {
  Box,
  Typography 
} from '@mui/material';
import StoreButton from '../../../../../Components/Forms/Store/StoreButton.js';
import { formatPathToArticleHandPlay } from '../../../../../helpers/hand.js';

const HandBox = styled(Box)(({ theme }) => ({
  border: '1px solid #BFBFBF',
  backgroundColor: theme.palette.background.default,
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem 2rem',
  minHeight: 66,
  marginBottom: theme.spacing(2)
}));

export default function ArticleHandList({ article }) {
  if (article.hands && article.hands.length === 1) {
    const pathToHand = formatPathToArticleHandPlay(article, article.hands[0]);
    return (
      <Link to={pathToHand} style={{ textDecoration: 'none' }}>
        <StoreButton label='Play hand' />
      </Link>
    )
  } else {
    return (
      <>
        {
          article.hands && article.hands.length > 0 && article.hands.map(hand => {
            const pathToHand = formatPathToArticleHandPlay(article, hand);
            return (
              <HandBox key={hand.id}>
                <Typography variant='h3'>
                  {hand.title}
                </Typography>
                <Link to={pathToHand} style={{ textDecoration: 'none' }}>
                  <StoreButton label='Play hand' />
                </Link>
              </HandBox>
            )
          })
        }
      </>
    );
  }
};

