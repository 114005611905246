import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Grid,
  Button,
  Typography,
  Box,
  Link
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { formatURLTitle } from '../../../helpers/http';
import MoreMenuButton from '../../../Components/MoreMenuButton';
import ArticleMenu from './ArticleMenu';

// Article table
function ArticleTable({ rows, subscriptionId, subscriptionTitle, refetchSubscriptions }) {
  const [menuOpen, setMenOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const getArticleLink = (row) => {
    return `/admin/subscriptions/${subscriptionId}/${formatURLTitle(subscriptionTitle)}/articles/${row.id}/${formatURLTitle(row.title)}`;
  };

  const handleMoreMenuClick = row => (event) => {
    setSelectedArticle(row);
    setAnchorEl(event.currentTarget);
    setMenOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenOpen(false);
    setSelectedArticle(null);
  };

  return (
    <TableContainer component={Paper}>
      <Table style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Publish date</TableCell>
            <TableCell>Free</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              hover
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{<Link href={getArticleLink(row)}>{row.title}</Link>}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{row.publishDateTimeUniversal}</TableCell>
              <TableCell>{row.freeToRead && <DoneIcon />}</TableCell>
              <TableCell>
                <MoreMenuButton onClick={handleMoreMenuClick(row)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ArticleMenu
        anchorEl={anchorEl}
        article={selectedArticle}
        subscriptionId={subscriptionId}
        open={menuOpen}
        onClose={handleMenuClose}
        refetchSubscriptions={refetchSubscriptions}
      />
    </TableContainer>
  );
}

export default function Articles ({ subscription, refetchSubscription }) {
  const navigate = useNavigate();

  const handleAddClick = () => {
    navigate(`/admin/subscriptions/${subscription.id}/${subscription.title}/articles/add`);
  };

  return (
    <Paper style={{ padding: 20 }}>
      <Grid
        container
        rowSpacing={2}
        alignItems='flex-start'
        justifyContent='space-between'
        flexDirection='column'
      >
        <Grid item style={{ width: '100%' }}>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='h5' gutterBottom>
              Articles
            </Typography>
            <Button
              onClick={handleAddClick}
              variant='contained'
            >
              Add Article
            </Button>
          </Box>
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <ArticleTable
            rows={subscription.articles}
            subscriptionId={subscription.id}
            subscriptionTitle={subscription.title}
            refetchSubscriptions={refetchSubscription}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}