import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import {
  subscriptionQueries,
  userQueries
} from '../../../graphql/queries';
import { makeStyles } from '@mui/styles';
import StoreSectionContainer from '../../../Components/Store/StoreSectionContainer.js';
import LoggedOut from './LoggedOut.js';
import GridViewSkeleton from '../../../Components/Skeletons/GridViewSkeleton.js';
import Empty from './Empty.js';
import StoreItems from '../../../Components/Store/StoreItems.js';
import { useTextTranslation } from '../../../Hooks';
import { UserContext, DrawerContext } from '../../../Context';
import {
  formatPurchasesAndSubscriptionsForLibrary,
  getProductListFromPurchases
} from '../../../helpers/purchase.js';

const useStyles = makeStyles((theme) => ({
  items: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-8rem'
    }
  },
  avatar: {
    marginRight: '1rem'
  }
}));

export default function Library () {
  const [userPurchases, setUserPurchases] = useState(null);

  const { user, setUser } = useContext(UserContext);
  const { openDrawer } = useContext(DrawerContext);

  const navigate = useNavigate();
  const classes = useStyles();

  const { data: subscriptionData } = useQuery(subscriptionQueries.Subscriptions);
  const [getUserPurchases] = useLazyQuery(userQueries.UserPurchases, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.userPurchases) {
        const updatedUser = { ...user };
        // so the 'owned' text shows we need to update the user's products
        updatedUser.products = getProductListFromPurchases(data.userPurchases.purchases);
        // set the userPurchases for use in the grid
        setUserPurchases(data.userPurchases);
        // we also need to update the user in context so the rest of the site knows whats up
        setUser({ ...updatedUser, activeSubscriptions: data.userPurchases.activeSubscriptions });
      }
    },
    onError: (err) => {
      console.log('getUserPurchases err', err);
    }
  });

  useEffect(() => {
    getUserPurchases();
  }, [getUserPurchases]);
  
  const myLibrary = useTextTranslation('my-library');

  const handleLoginClick = () => {
    openDrawer('login');
  };

  const handleRegisterClick = () => {
    navigate('/register');
  };

  const handleStoreClick = () => {
    navigate('/products');
  };

  if (!user) {
    return (
      <LoggedOut
        onLoginClick={handleLoginClick}
        onRegisterClick={handleRegisterClick}
      />
    );
  } 

  if (!subscriptionData || !userPurchases) {
    return <GridViewSkeleton />;
  } else {

    // combine subscriptions and products to form the librarys grid items
    const productsAndSubscriptions = formatPurchasesAndSubscriptionsForLibrary(
      userPurchases,
      subscriptionData.subscriptions
    );

    return (
      <>
        <StoreSectionContainer
          heading={myLibrary}
        >
          {
            productsAndSubscriptions && productsAndSubscriptions.length > 0
              ? (
                <div className={classes.items}>
                  <StoreItems
                    products={productsAndSubscriptions}
                    hidePrice
                  />
                </div>
                )
              : <Empty onStoreClick={handleStoreClick} />
          }
        </StoreSectionContainer>
      </>
    );
  }
};
