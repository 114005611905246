import React from 'react';
import {
  Paper,
  Typography
} from '@mui/material';
import StoreButton from '../../../../Components/Forms/Store/StoreButton';
export default function NoActiveSubscription ({ onStoreClick }) {
  return (
    <Paper>
        <Typography>
          You have no active subscriptions
        </Typography>
        <div>
          <StoreButton
            onClick={onStoreClick}
            label='go-to-store'
          />
        </div>
      </Paper>
  );
}