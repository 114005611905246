import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Avatar,
  Typography,
  Box
} from '@mui/material';
import Truncate from 'react-text-truncate';
import SubscriptionChip from '../../../../Components/Store/Subscriptions/SubscriptionChip.js';
import { formatCDNPath } from '../../../../helpers/image.js';

const useStyles = makeStyles((theme) => ({
  item: {
    background: '#fff',
    borderRadius: 6,
    boxShadow: theme.boxShadow,
    padding: '3rem',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  avatar: {
    width: '10rem',
    height: '10rem',
    marginRight: '2rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '2rem'
    }
  },
  content: {
    flexGrow: 1
  },
  authorName: {
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.styleguide.maroon
    }
  },
  bio: {
    marginTop: '1rem',
    minHeight: '7.2rem'
  },
  bioLink: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline'
    }
  }
}));

const AuthorListItem = ({ author }) => {
  const classes = useStyles();

  const avatarPath = formatCDNPath(author.avatarPath);
  const authorDetailsUrl = `/authors/${author.id}/${author.fullName}`;
  const hasSubscription = author.products.find(p => p.type === 'Subscription');

  return (
    <Grid item xs={12} sm={6}>
      <Grid
        container
        className={classes.item}
      >
        <Grid item className={classes.avatar}>
          <Link to={authorDetailsUrl}>
            <Avatar
              alt={author.fullName}
              src={avatarPath}
              className={classes.avatar}
            />
          </Link>
        </Grid>
        <Grid item className={classes.content}>
          <Grid
            container
            direction='column'
            justifyContent='space-between'
            alignItems='stretch'
          >
            <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Link to={authorDetailsUrl} className={classes.authorName}>
                <Typography variant='h3'>{author.fullName}</Typography>
              </Link>
              {
                hasSubscription && (
                  <Box style={{ marginTop: -6 }}>
                    <SubscriptionChip />
                  </Box>
                )
              }
            </Grid>
            <Grid item className={classes.bio}>
              <Link to={authorDetailsUrl} className={classes.bioLink}>
                <Truncate
                  line={3}
                  truncateText='…'
                  text={author.bio}
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

AuthorListItem.propTypes = {
  author: PropTypes.object.isRequired
};

export default AuthorListItem;
