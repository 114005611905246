import React, { useState, useContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { dynamoProductQueries } from '../../../../graphql/queries';
import GridViewSkeleton from '../../../../Components/Skeletons/GridViewSkeleton.js';
import ProductSection from '../../../../Components/Store/Products/ProductSection.js';
import FilteredProductSection from '../../../../Components/Store/Products/FilteredProductSection.js';
import { convertLangCodeToGraphqlEnum } from '../../../../helpers/locale.js';
import FeaturedAuthors from '../../../../Components/Store/FeaturedAuthors/FeaturedAuthors';
import SittingManCta from '../../../../Components/Sales/SittingManCta.js';
import { groupPromotedProducts } from '../../../../helpers/productTagFilter.js';
import { markPurchasedProducts } from '../../../../helpers/purchase.js';
import { LocaleContext, UserContext } from '../../../../Context';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.down('lg')]: {
      // allow for the scrollable products to break out
      // of the central column
      ...theme.extendableBackground
    }
  },
  // allows the still not convinced background
  // to extend all the way left and right
  stillNotConvinced: {
    ...theme.extendableBackground,
    // not exactly sure why these numbers work but they do
    left: 'calc(57.5rem - 50vw)',
    right: 0,
    position: 'relative',
    paddingLeft: 0,
    paddingRight: 0,
    // pull the footer in
    marginBottom: '-6rem',
    [theme.breakpoints.down('lg')]: {
      left: '-1.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-4rem'
    }
  }
}));

const Products = () => {
  const { locale } = useContext(LocaleContext);
  const { user } = useContext(UserContext);
  const [products, setProducts] = useState(null);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const classes = useStyles();

  const [getProducts] = useLazyQuery(dynamoProductQueries.DynamoPromotedProducts, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      let products = data.dynamoPromotedProducts.products;
      // mark purchased products
      products = markPurchasedProducts(products, user);
      setProducts(products);
    }
  });

  useEffect(() => {
    if (locale) {
      if (locale.language === 'nl-nl') {
        navigate('/products/all');
      } else {
        getProducts({
          variables: {
            locale: {
              language: convertLangCodeToGraphqlEnum(locale.language),
              currency: locale.currency
            }
          }
        });
      }
    }
  }, [getProducts, locale, navigate]);

  if (!products) {
    return (
      <>
        <GridViewSkeleton nowrap />
        <Box sx={{ height: '6rem' }}>&nbsp;</Box>
        <GridViewSkeleton nowrap />
      </>
    );
  }

  const groupedPromotedProducts = groupPromotedProducts(products);

  const hasDifficultyPromotedProducts = groupedPromotedProducts.Beginner ||
    groupedPromotedProducts.Improver ||
    groupedPromotedProducts.Intermediate ||
    groupedPromotedProducts.Advanced;

  const hasTypePromotedProducts = groupedPromotedProducts['Bidding only'] ||
    groupedPromotedProducts['Declarer bidding and play'] ||
    groupedPromotedProducts['Defender bidding and play'] ||
    groupedPromotedProducts.Mixed;

  return (
    <>
      {groupedPromotedProducts.subscriptions && (
        <div className={classes.wrapper}>
          <ProductSection
            products={groupedPromotedProducts.subscriptions}
            title={t('subscriptions')}
            viewAllPath='/products/all?Purchase+type=Subscription'
          />
        </div>
      )}
      {groupedPromotedProducts.latest && (
        <div className={classes.wrapper}>
          <ProductSection
            products={groupedPromotedProducts.latest}
            title={t('latest-products')}
            viewAllPath='/products/all'
          />
        </div>
      )}
      {hasDifficultyPromotedProducts && (
        <div className={classes.wrapper}>
          <FilteredProductSection
            productsByGroup={{
              Beginner: groupedPromotedProducts.Beginner,
              Improver: groupedPromotedProducts.Improver,
              Intermediate: groupedPromotedProducts.Intermediate,
              Advanced: groupedPromotedProducts.Advanced
            }}
            title={t('play-at-your-level')}
            viewAllPath='/products/all?Difficulty='
          />
        </div>
      )}
      {hasTypePromotedProducts && (
        <div className={classes.wrapper}>
          <FilteredProductSection
            productsByGroup={{
              'Bidding only': groupedPromotedProducts['Bidding only'],
              'Declarer bidding and play': groupedPromotedProducts['Declarer bidding and play'],
              'Defender bidding and play': groupedPromotedProducts['Defender bidding and play'],
              Mixed: groupedPromotedProducts.Mixed
            }}
            title={t('choose-your-type')}
            viewAllPath='/products/all?Type='
          />
        </div>
      )}
      <FeaturedAuthors />
      <div className={classes.stillNotConvinced}>
        <SittingManCta
          heading={t('see-bridgebee-in-action')}
          body={t('we-have-lots-of-hands')}
          cta='practice-a-free-hand'
          onCtaClick={() => { navigate('/products/1037/bridgebee-free-sample-hands'); }}
        />
      </div>
    </>
  );
};

export default Products;
