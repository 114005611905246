import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import EmbedAppBar from '../../../../Components/Layout/EmbedAppBar';
import { dynamoProductQueries } from '../../../../graphql/queries';
import { convertLangCodeToGraphqlEnum } from '../../../../helpers/locale.js';
import ErrorNotification from '../../../../Components/Errors/ErrorNotification.js';
import Product from './Product.js';
import MainContainer from '../../../../Components/Layout/MainContainer.js';

const Wrapper = ({ children }) => (
  <div style={{ paddingTop: 50 }}>
    {children}
  </div>
);

const LinkedProduct = () => {
  const { language, linkId } = useParams();

  const variables = {
    id: linkId,
    locale: {
      language: convertLangCodeToGraphqlEnum(language),
      currency: 'GBP' // this is hardcoded as the graphql query expects the locale but it doesn't use the currency part, just the language
    }
  };

  const { data, error } = useQuery(dynamoProductQueries.DynamoProductByLinkId, {
    variables
  });

  if (error) {
    return (
      <ErrorNotification error='This product cannot be viewed' />
    );
  }

  if (!data) {
    return null;
  }

  return (
    <>
      <EmbedAppBar />
      <MainContainer>
        <Wrapper>
          <Product
            product={data.dynamoProductByLinkId}
            linkId={linkId}
          />
        </Wrapper>
      </MainContainer>
    </>
  );
};

export default LinkedProduct;
