import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import ToolBarButton from './ToolBarButton.js';
import { ButtonGroup } from '@mui/material';
import BoldIcon from '@mui/icons-material/FormatBold';
import ItalicIcon from '@mui/icons-material/FormatItalic';
import UnderlineIcon from '@mui/icons-material/FormatUnderlined';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import LinkPopover from './LinkPopover.js';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '1rem 1rem 0',
    paddingBottom: '1rem',
    borderBottom: '1px solid #ccc'
  },
  count: {
    position: 'absolute',
    top: 37,
    right: 15
  },
  group: {
    marginRight: '1rem'
  },
  green: {
    color: '#006400' // '#00FF00'
  },
  amber: {
    color: '#FFAA00' // '#FFBF00'
  },
  red: {
    color: '#b3002d' // '#FF0000'
  }
}));

const ToolBar = ({ editor, charCount, amberCharMin, redCharMin, onMagicButtonClick }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [previousUrl, setPreviousUrl] = useState('');

  const getCountClass = () => {
    let className = classes.green;
    if (charCount > (amberCharMin || 100)) {
      className = classes.amber;
    }
    if (charCount > (redCharMin || 200)) {
      className = classes.red;
    }
    return className;
  };

  const handleLinkClick = (e) => {
    setPreviousUrl(editor.getAttributes('link').href);
    setAnchorEl(e.currentTarget);
  };

  const handleLinkSaved = (url) => {
    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url, target: '_blank' })
      .run();
  };

  if (!editor) {
    return null;
  }

  return (
    <div className={classes.container}>
      <ButtonGroup className={classes.group}>
        <ToolBarButton
          IconComponent={BoldIcon}
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          isActive={editor.isActive('bold')}
        />
        <ToolBarButton
          IconComponent={ItalicIcon}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          isActive={editor.isActive('italic')}
        />
        <ToolBarButton
          IconComponent={UnderlineIcon}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleUnderline()
              .run()
          }
          isActive={editor.isActive('underline')}
        />
      </ButtonGroup>
      <ButtonGroup className={classes.group}>
        <ToolBarButton
          IconComponent={LinkIcon}
          onClick={handleLinkClick}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .extendMarkRange('link')
              .setLink({ href: '' })
              .run()
          }
        />
        <ToolBarButton
          IconComponent={LinkOffIcon}
          onClick={() => editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .unsetLink()
            .run()}
          disabled={!editor.can()
            .chain()
            .focus()
            .extendMarkRange('link')
            .unsetLink()
            .run()}
        />
      </ButtonGroup>
      {
        onMagicButtonClick && (
          <ButtonGroup>
            <ToolBarButton
              IconComponent={AutoAwesomeIcon}
              onClick={onMagicButtonClick}
            />
          </ButtonGroup>
        )
      }
      <LinkPopover
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onSave={handleLinkSaved}
        key={previousUrl}
        initialUrl={previousUrl}
      />
      <div className={classes.count}>Character count: <span className={getCountClass()}>{charCount}</span></div>
    </div>
  );
};

export default ToolBar;
