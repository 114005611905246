import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { 
  useNavigate,
  useOutletContext
} from 'react-router-dom';
import { userQueries } from '../../../../graphql/queries';
import { mutations } from '../../../../graphql';
import ConfirmModal from '../../../../Components/Store/ConfirmModal.js';
import NoActiveSubscription from './NoActiveSubscriptions.js';
import SubscriptionTable from './SubscriptionsTable.js';

export default function Subscriptions () {
  const { handleSubmitSuccess } = useOutletContext();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmType, setConfirmType] = useState('');
  const [userSubscriptionPlanId, setUserSubscriptionPlanId] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  
  // get user subscription plans
  const { data, refetch } = useQuery(userQueries.UserSubscriptionPlans);

  const [cancelSubscription] = useMutation(mutations.CancelSubscription)
  const [changeSubscription] = useMutation(mutations.ChangeSubscription)
  
  if (!data) {
    return null;
  }

  const handleCancelSubscription = (userSubscriptionPlanId) => {
    setConfirmOpen(true);
    setConfirmType('Cancel');
    setUserSubscriptionPlanId(userSubscriptionPlanId);
  };

  const handleChangeSubscription = (userSubscriptionPlanId) => {
    setConfirmOpen(true);
    setConfirmType('Change');
    setUserSubscriptionPlanId(userSubscriptionPlanId);
  };

  const handleCancelSubscriptionConfirm = async () => {
    setLoading(true);
    await cancelSubscription({
      variables: {
        userSubscriptionPlanId
      }
    });
    await refetch();
    setConfirmOpen(false);
    setLoading(false);
    handleSubmitSuccess('Subscription cancelled');
  };

  const handleChangeSubscriptionConfirm = async () => {
    setLoading(true);
    await changeSubscription({
      variables: {
        userSubscriptionPlanId
      }
    });
    await refetch();
    setConfirmOpen(false);
    setLoading(false);
    handleSubmitSuccess('Subscription changed to yearly billing');
  }
  
  const handleCancelModal = () => {
    setConfirmOpen(false);
  };

  const handleStoreClick = () => {
    navigate('/subscriptions');
  };

  if (data.userSubscriptionPlans.length === 0) {
    return (
      <NoActiveSubscription onStoreClick={handleStoreClick} />
    );
  }

  return (
    <>
      <SubscriptionTable
        userSubscriptionPlans={data.userSubscriptionPlans}
        onCancelSubscriptionClick={handleCancelSubscription}
        onChangeSubscriptionClick={handleChangeSubscription}
      />
      <ConfirmModal
        open={confirmOpen}
        title='Are you sure?'
        onConfirmClick={confirmType === 'Cancel' ? handleCancelSubscriptionConfirm : handleChangeSubscriptionConfirm}
        onCancelClick={handleCancelModal}
        cancelLabel='No'
        confirmLabel='Yes'
        loading={loading}
      >
        {
          confirmType === 'Cancel' ? (
            <>Are you sure you wish to cancel your subscription?</>
          )
          : (
            <>Are you sure you wish to change your subscription?</>
          )
        }
        
      </ConfirmModal>
    </>
  );
};
