import React from 'react';
import Authors from '../../Queries/Authors.js';
import AuthorList from './AuthorList.js';
import StoreSectionContainer from '../StoreSectionContainer.js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FeaturedAuthors = ({ backgroundColor, extraWhitespace }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleViewAllClick = () => {
    navigate('/authors');
  };

  return (
    <StoreSectionContainer
      heading={t('our-expert-teachers-and-authors')}
      subheading={t('learn-from-the-best')}
      ctaButtonLabel='view-all'
      onCtaButtonClick={handleViewAllClick}
      backgroundColor={backgroundColor}
      extraWhitespace={extraWhitespace}
    >
      <Authors>
        {(authors) => (
          <AuthorList
            authors={authors}
          />
        )}
      </Authors>
    </StoreSectionContainer>
  );
};

export default FeaturedAuthors;
