import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// a pattern of card suits that can be used for decoration

const useStyles = makeStyles(() => ({
  suitPattern: {
    backgroundImage: 'url("/img/components/sales/suit-pattern.svg")',
    opacity: ({ opacity }) => (opacity || 0.23),
    width: 488,
    height: 655,
    position: 'absolute',
    left: 0,
    top: 0
  }
}));

const SuitPattern = (props) => {
  const { opacity, className } = props;
  const classes = useStyles({ opacity });

  return (
    <div
      className={clsx(classes.suitPattern, className)}
      aria-hidden='true'
    />
  );
};

SuitPattern.propTypes = {
  opacity: PropTypes.number,
  className: PropTypes.string
};

export default SuitPattern;
