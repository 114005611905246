import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import UnderlineExtension from '@tiptap/extension-underline';
import LinkExtension from '@tiptap/extension-link';
import ToolBar from './ToolBar.js';
import { stripHtml } from '../../../helpers/global.js';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'left',
    position: 'relative',
    marginTop: '1em'
  },
  editor: {
    borderRadius: 4,
    backgroundColor: '#fff',
    border: '1px solid #ccc'
  },
  editorInvalid: {
    borderColor: theme.palette.error.main
  },
  editorBody: {
    '& div': {
      height: '100%'
    },
    '& .ProseMirror': {
      height: '100%',
      padding: '1rem',
      overflow: 'auto',
      fontFamily: 'Roboto',
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      '&:focus, &:focus-visible': {
        outline: `2px solid ${theme.palette.styleguide.maroon}`
      }
    }
  },
  caption: {
    color: '#666',
    marginLeft: 3
  },
  captionInvalid: {
    color: theme.palette.error.main,
    marginLeft: 3
  }
}));

const RichText = ({ name, value, label, invalid, height, amberCharMin, redCharMin, onChange, onMagicButtonClick, aiSummaryHack }) => {
  const classes = useStyles();

  const getCharCount = (chars) => {
    if (chars) {
      return stripHtml(chars).length;
    }
    return 0;
  };

  const handleEditorChange = (html) => {
    onChange({
      name,
      value: html
    });
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      UnderlineExtension,
      LinkExtension.configure({
        openOnClick: false
      })
    ],
    onUpdate: ({ editor }) => {
      handleEditorChange(editor.getHTML());
    },
    content: value
  }, [aiSummaryHack]);

  return (
    <div className={classes.container}>
      <Typography
        className={clsx(classes.caption, invalid ? classes.captionInvalid : null)} variant='caption'
      >
        {label}
      </Typography>
      <div className={clsx(classes.editor, invalid && classes.editorInvalid)}>
        <ToolBar
          editor={editor}
          charCount={getCharCount(aiSummaryHack || value)}
          amberCharMin={amberCharMin}
          redCharMin={redCharMin}
          onMagicButtonClick={onMagicButtonClick}
        />
        <div className={classes.editorBody} style={{ height: height || 109 }}>
          <EditorContent editor={editor} />
        </div>
      </div>
    </div>
  );
};

RichText.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  invalid: PropTypes.bool
};

export default RichText;
