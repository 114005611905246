import React from 'react';
import BBAccordion from '../../Components/BBAccordion.js';
import StoreSectionContainer from '../../Components/Store/StoreSectionContainer.js';
import { useTranslation } from 'react-i18next';

const FAQ = () => {
  const { t } = useTranslation();

  return (
    <StoreSectionContainer
      heading={t('faq')}
      extraWhitespace
    >
      <BBAccordion
        items={[
          // TODO make things translateable when we get the actual copy
          {
            key: 'a',
            summary: 'How much content will I receive?',
            details: 'Lorem ipsum'
          },
          {
            key: 'b',
            summary: 'Where do I start?',
            details: 'Lorem ipsum'
          },
          {
            key: 'c',
            summary: 'Can anyone play?',
            details: 'Lorem ipsum'
          },
          {
            key: 'd',
            summary: 'Why?',
            details: 'Lorem ipsum'
          }
        ]}
      />
    </StoreSectionContainer>
  );
};

export default FAQ;

