import ReactGA from 'react-ga';
import _ from 'lodash';
import { stripTypeNameFromMutationVars } from './gql.js';
import { getProductPriceByCurrency } from './cart.js';

// create payment method using stripe.js (sent from the client)
const createPaymentMethod = async (stripe, paymentElement, paymentType, customer, savePaymentMethod) => {
  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: paymentType,
    [paymentType]: paymentElement,
    billing_details: {
      name: customer.firstName + ' ' + customer.lastName,
      email: customer.email
    },
    metadata: {
      save: savePaymentMethod
    }
  });
  if (error) {
    throw new Error(error.message);
  }
  return paymentMethod.id;
};

// create payment intent (via bb api)
const createPaymentIntent = async (
  createPurchase,
  currency,
  savePaymentMethod,
  cart,
  customer,
  paymentMethodId,
  selectedPaymentType
) => {
  // only send the cart props graphql is looking for
  const cartItems = await cart.items.map(({ id, price, discount, type, gift }) => {
    const formmattedCart = {
      itemId: id,
      price: getProductPriceByCurrency(currency, price),
      discount,
      type
    };
    if (gift) {
      const { toFirstName, toLastName, toEmail, message } = gift;
      formmattedCart.gift = {
        toFirstName,
        toLastName,
        toEmail,
        message
      };
    }
    return formmattedCart;
  });

  // create the data object to send to the api
  const purchaseData = {
    currency: currency.toUpperCase(),
    status: 'Pending',
    savePaymentMethod,
    cart: cartItems,
    voucherId: cart.voucher && cart.voucher.id,
    user: {
      id: customer.id,
      email: customer.email,
      stripeCustomerId: customer.stripeCustomerId,
      stripePaymentMethodId: paymentMethodId
    },
    paymentType: _.startCase(selectedPaymentType)
  };

  // call serverside to create payment intent
  const { data } = await createPurchase({
    variables: stripTypeNameFromMutationVars(purchaseData)
  });
  return data.createPurchase;
};

const handlePurchaseComplete = async (completePurchase, purchaseId) => {
  await completePurchase({
    variables: {
      id: purchaseId
    }
  });
};

const handlePurchaseFailed = async (failPurchase, purchaseId, message) => {
  await failPurchase({
    variables: {
      id: purchaseId,
      failureMessage: message
    }
  });
};

// confirm a card payment (only for payment intents that require action)
const confirmCardPurchase = async (stripe, cardElement, clientSecret, purchaseId) => {
  const result = await stripe.confirmCardPayment(clientSecret, {
    payment_method: {
      card: cardElement
    }
  });
  if (result.error) {
    const err = new Error(result.error.message);
    // we want to show users a message based on error type
    err.type = result.error.type;
    err.code = result.error.code;
    throw err;
  }
  if (result.paymentIntent.status !== 'succeeded') {
    // its not succeeded so mark as failed
    const message = `Received a status other than succeeeded from stripe.handleCardPayment - ${result.paymentIntent.status}`;
    throw new Error(message);
  }
  return result.paymentIntent.status;
};

const getFormattedPrice = (product, discount) => {
  const currencySymbol = product.priceLocalFormatted.substring(0, 1);
  let finalPrice = product.priceLocal;
  if (discount > 0) {
    const discountPrice = (discount / 100) * product.priceLocal;
    finalPrice = (product.priceLocal - discountPrice).toFixed(2);
  }
  return `${currencySymbol}${finalPrice}`;
};

const recordGoogleAnalyticsPurchase = (purchaseId, product, currency) => {
  currency = currency.toUpperCase();
  ReactGA.event({
    category: 'Conversion',
    action: 'Purchase',
    label: product.title,
    value: null
  });
  // send GA ecommerce measurement
  ReactGA.plugin.require('ecommerce');
  ReactGA.plugin.execute('ecommerce', 'addItem', {
    id: purchaseId,
    name: product.title,
    sku: product.id,
    price: product.priceLocal,
    currency,
    quantity: '1'
  });
  ReactGA.plugin.execute('ecommerce', 'addTransaction', {
    id: purchaseId,
    revenue: product.priceLocal,
    currency
  });
  ReactGA.plugin.execute('ecommerce', 'send');
  ReactGA.plugin.execute('ecommerce', 'clear');
};

export {
  createPaymentMethod,
  createPaymentIntent,
  handlePurchaseComplete,
  handlePurchaseFailed,
  confirmCardPurchase,
  getFormattedPrice,
  recordGoogleAnalyticsPurchase
};
