import React from 'react';
import {
  useParams,
  useLocation
} from 'react-router-dom';
import { Mutation } from '@apollo/react-components';
import { mutations } from '../../../../graphql';
import HandForm from './HandForm.js';
import HandById from '../../../../Components/Queries/HandById.js';
import ProductByIdAdmin from '../../../../Components/Queries/ProductByIdAdmin.js';
import { BBSnackbarConsumer } from '../../../../Components/BBSnackbar/BBSnackbarContext.js';

const HandDetails = () => {
  const {
    parentId,
    handId
  } = useParams();

  const { state: linkState } = useLocation(); // state passed from <Link/> in EditableHandsTable
  const { parent, subscription } = linkState;

  if (handId) {
    // get hand details before rendering hand pages
    return (
      <HandById id={parseInt(handId)}>
        {handData => (
          <Mutation
            mutation={mutations.UpsertHand}
          >
            {upsertHand => (
              <BBSnackbarConsumer>
                {({ openSnackbar }) => (
                  <HandForm
                    hand={handData}
                    upsertHand={upsertHand}
                    openSnackbarYouGit={openSnackbar}
                    parent={parent}
                    subscription={subscription}
                  />
                )}
              </BBSnackbarConsumer>
            )}
          </Mutation>
        )}
      </HandById>
    );
  } else {
    // just render the hand pages
    return (
      <ProductByIdAdmin id={parseInt(parentId)}>
        {(productData, loading) => {
          if (!loading) {
            return (
              <Mutation
                mutation={mutations.UpsertHand}
              >
                {upsertHand => (
                  <BBSnackbarConsumer>
                    {({ openSnackbar }) => (
                      <HandForm
                        hand={{
                          status: 'Draft',
                          title: 'Manually added hand',
                          tags: [],
                          product: productData.productByIdAdmin
                        }}
                        upsertHand={upsertHand}
                        openSnackbarYouGit={openSnackbar}
                        parent={parent}
                        subscription={subscription}
                      />
                    )}
                  </BBSnackbarConsumer>
                )}
              </Mutation>
            );
          } else {
            return null;
          }
        }}
      </ProductByIdAdmin>
    );
  }
};

export default HandDetails;
