import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import MiniCard from '../MiniCard/MiniCard.js';
import { convertSeatCodeToFullWord } from '../../../helpers/auction.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    whiteSpace: 'nowrap'
  },
  declarer: {
    lineHeight: '2.5em',
    fontWeight: 'bold'
  },
  declarerLarge: {
    lineHeight: '2em',
    fontSize: 16
  },
  card: {
    fontSize: '0.7em',
    marginRight: theme.spacing(1)
  }
}));

export default function Contract ({ contract, declarer, size, theme }) {
  const classes = useStyles();

  const { t } = useTranslation();

  const translatedDeclarer = t(convertSeatCodeToFullWord(declarer).toLowerCase())
    .toLowerCase();

  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
      className={classes.container}
      wrap='nowrap'
    >
      <Grid item xs className={classes.card}>
        <MiniCard
          suit={contract.denomination}
          value={contract.level}
          isBid
          size={size || null}
          theme={theme || null}
        />
      </Grid>
      {
        contract.risk !== ''
          ? <Grid item xs className={classes.card}><MiniCard value={contract.risk} isBid size={size || null} theme={theme || null} /></Grid>
          : null
      }
      <Grid item xs className={clsx(classes.declarer, size === 'large' ? classes.declarerLarge : null)}>
        <span>
          {t('by-declarer', { declarer: translatedDeclarer })}
        </span>
      </Grid>
    </Grid>
  );
};

Contract.propTypes = {
  contract: PropTypes.object.isRequired,
  declarer: PropTypes.string.isRequired,
  size: PropTypes.string,
  theme: PropTypes.string
};