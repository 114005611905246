import React from 'react';
import ErrorNotification from './ErrorNotification.js';
import { logout } from '../../helpers/auth.js';

// https://reactjs.org/docs/error-boundaries.html
// https://codepen.io/gaearon/pen/wqvxGa?editors=0010

class BBErrorBoundary extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }

  // Catch errors in any components below and re-render with error message
  componentDidCatch (error, errorInfo) {
    this.setState({
      error,
      errorInfo
    });
  }

  render () {
    const { error, errorInfo } = this.state;

    if (errorInfo) {
      if (error && error.message.indexOf('401') > -1) {
        logout();
        window.location = '/login';
        return null;
      } else {
        return (
          <ErrorNotification
            error={error}
            errorInfo={errorInfo}
          />
        );
      }
    } else {
      // Normally, just render children
      return this.props.children;
    }
  }
}

export default BBErrorBoundary;
