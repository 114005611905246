import React from 'react';
import {
  Box,
  Paper,
  Typography
} from '@mui/material';
import { styled } from '@mui/styles';
import TickIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/RadioButtonUnchecked';
import Price from '../../../../../Components/Store/Price.js';
import { capitalize } from '../../../../../helpers/global.js';

const Button = styled(Paper)(({ theme, bordercolor }) => ({
  width: 136,
  maxWidth: '100%',
  height: 87,
  borderRadius: '7px !important',
  backgroundColor: '#fff',
  border: '2px solid',
  borderColor: bordercolor,
  '&:hover': {
    cursor: 'pointer'
  }
}));

const IconBox = styled(Box)({
  position: 'absolute',
  padding: 10
});

export default function PlanButton({ type, price, active, onClick }) {
  const handleClick = () => {
    onClick(type);
  }
  return (
    <Button onClick={handleClick} bordercolor={active ? '#4788B1' : '#fff'}>
      <IconBox>
        {
          active 
          ? <TickIcon sx={{ color: '#4788B1', fontSize: 18 }} /> 
          : <CircleIcon sx={{ color: '#8C8C8C', fontSize: 18 }} />
        }
      </IconBox>
      <Typography sx={{ padding: 1, fontSize: 14 }}>
        {capitalize(type)}
      </Typography>
      <Box sx={{ marginBottom: 2 }}>
        <Price price={`${price}`} />
      </Box>
    </Button>
  )
}
