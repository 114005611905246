import gql from 'graphql-tag';

const dynamoProductFragments = {
  listProduct: gql`
    fragment ListProduct on DynamoProduct {
      id
      productType
      images
      title
      language
      priceLocal
      priceLocalFormatted
      author {
        id
        fullName
        avatarPath
      }
      tagsCache
    }
  `,
  fullProduct: gql` 
    fragment FullProduct on DynamoProduct {
      id
      collection {
        id
        productType
        title
        images
        priceLocal
        priceLocalFormatted
      }
      productType
      title
      description
      images
      dateCreated
      price {
        id
        priceUSD
        priceGBP
        priceEUR
        priceCAD
        priceAUD
        priceNZD
      }
      priceLocal
      priceLocalFormatted
      onSale
      language
      author {
        id
        fullName
        avatarPath
      }
      tagsCache
      hands {
        id
        title
        freeToPlay
        userHands {
          id
          status
        }
      }
      sets {
        id
        productType
        title
        description
        images
        tagsCache
        priceLocal
        language
        author {
          id
          fullName
          avatarPath
        }
        hands {
          id
          title
          freeToPlay
          userHands {
            id
            status
          }
        }
      }
    }
  `
};

export default dynamoProductFragments;
