import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Typography,
  Avatar
} from '@mui/material';
import { formatURLTitle } from '../../helpers/http.js';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  avatar: {
    marginRight: '1rem'
  },
  link: {
    textDecoration: 'none',
    display: 'inine-flex',
    '&:hover p': {
      textDecoration: 'underline'
    }
  },
  whiteText: {
    color: '#ffffff'
  }
}));

const Author = ({ author, noAvatar, noLink, white }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const authorContents = (
    <>
      {
        !noAvatar &&
          <Avatar className={classes.avatar} alt={author.fullName} src={author.avatarPath} />
      }
      <Typography variant='body1' className={white && classes.whiteText}>
        {t('by')} {author.fullName}
      </Typography>
    </>
  );

  if (noLink) {
    return (
      <div className={classes.container}>
        {authorContents}
      </div>
    );
  } else {
    return (
      <div>
        <Link
          to={`/authors/${author.id}/${formatURLTitle(author.fullName)}`}
          className={clsx(classes.link, classes.container)}
        >
          {authorContents}
        </Link>
      </div>
    );
  }
};

Author.propTypes = {
  author: PropTypes.object.isRequired,
  noAvatar: PropTypes.bool,
  noLink: PropTypes.bool
};

export default Author;
