import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import striptags from 'striptags';
import Stepper from '@mui/material/Stepper';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MessageDialog from '../../../../Components/MessageDialog.js';
import BBSubmit from '../../../../Components/Forms/BBSubmit.js';
import Step0 from './Step0.js';
import Step1 from './Step1.js';
import Step2 from './Step2.js';
import Step3 from './Step3.js';
import PreviewModal from './PreviewModal.js';
import { stripTypeNameFromMutationVars } from '../../../../helpers/gql.js';
import { hasEnded } from '../../../../helpers/auction.js';
import {
  getHand,
  getStepProgress,
  getPathBackToParentFromHand
} from '../../../../helpers/hand.js';
import { parseError } from '../../../../helpers/error.js';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(1)
  },
  content: {
    paddingTop: theme.spacing(5)
    // marginLeft: theme.spacing(11)
  },
  footer: {
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(5),
    borderTop: '1px solid',
    borderColor: theme.palette.grey[300]
  },
  button: {
    margin: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(1),
    width: 190,
    display: 'inline-block'
  },
  feedback: {
    marginLeft: 10
  },
  step: {
    '& span': {
      cursor: 'pointer !important'
    }
  }
}));

function getSteps () {
  return ['Deal', 'Auction', 'Play', 'Summary'];
}

const HandForm = (props) => {
  const {
    hand,
    upsertHand,
    parent,
    subscription,
    openSnackbarYouGit
  } = props;

  const classes = useStyles();
  const navigate = useNavigate();

  const [handId, setHandId] = useState(hand ? hand.id : null);
  const [activeStep, setActiveStep] = useState(0);
  const [stepProgress, setStepProgress] = useState(getStepProgress(hand ? hand.handJSON : undefined));
  const [handJSON, setHandJSON] = useState(getHand(hand && hand.handJSON));
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewStartStep, setPreviewStartStep] = useState(null);

  const pathToParent = getPathBackToParentFromHand({ parent, subscription });

  const handlePreviewClick = () => {
    setPreviewOpen(true);
    setPreviewStartStep(null);
  };

  const handlePreviewTrickClick = (previewStartStep) => {
    setPreviewOpen(true);
    setPreviewStartStep(previewStartStep);
  };

  const handleStepClick = step => () => {
    setActiveStep(step);
  };

  const handleNext = () => {
    handleSaveHand();
    setActiveStep(activeStep + 1);
  };

  const handleSendForApproval = () => {
    handleSaveHand('Pending');
    setTimeout(() => {
      return navigate(pathToParent);
    }, 500);
  };

  const handleSaveAndExit = (e) => {
    if (e) {
      e.preventDefault();
    }
    handleSaveHand();
    setTimeout(() => {
      return navigate(pathToParent);
    }, 500);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const showWarningIfChangingCriticalData = (oldHandJSON, newHandJSON) => {
    if (activeStep < stepProgress) {
      // we are editing an earlier step to the one we are on
      switch (activeStep) {
        case 0:
          if (!_.isEqual(oldHandJSON.deal, newHandJSON.deal) ||
            oldHandJSON.dealer !== newHandJSON.dealer ||
            oldHandJSON.viewpoint !== newHandJSON.viewpoint
          ) {
            return true;
          }
          break;
        case 1:
          if (!_.isEqual(oldHandJSON.contract, newHandJSON.contract) && oldHandJSON.auctionOnly === false) {
            return true;
          }
          break;
        default:
          break;
      }
    }
    return false;
  };

  const handleStepChange = (prop) => {
    const oldHandJSON = _.cloneDeep(handJSON);
    let newHandJSON = _.cloneDeep(oldHandJSON);
    newHandJSON = Object.assign(newHandJSON, prop);
    if (showWarningIfChangingCriticalData(oldHandJSON, newHandJSON)) {
      setDialogOpen(true);
    } else {
      setHandJSON(newHandJSON);
      setStepProgress(getStepProgress(newHandJSON));
    }
  };

  const handleUnavedComment = () => {
    openSnackbarYouGit('You have an unsaved comment, please Save or Cancel', 'warning');
  };

  const handleCommentsSaved = (commentsForViewpointOrDummy) => {
    const message = `Comment${commentsForViewpointOrDummy ? 's' : ''} added`;
    openSnackbarYouGit(message, 'success');
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        // return 'Deal';
        return <Step0 hand={handJSON} onChange={handleStepChange} />;
      case 1:
        // return 'Auction';
        return (
          <Step1
            hand={handJSON}
            onChange={handleStepChange}
            onUnavedComment={handleUnavedComment}
            onCommentsSaved={handleCommentsSaved}
            onChangeViewpointClick={handleBack}
          />
        );
      case 2:
        // return 'Play';
        return (
          <Step2
            hand={handJSON}
            onChange={handleStepChange}
            onUnavedComment={handleUnavedComment}
            onCommentsSaved={handleCommentsSaved}
            showPreview={handJSON.play.length === 13}
            onPreviewClick={handlePreviewTrickClick}
          />
        );
      case 3:
        // return 'Summary';
        return (
          <Step3
            hand={handJSON}
            onChange={handleStepChange}
          />
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  const nextDisabled = () => {
    let isDisabled = true;
    switch (activeStep) {
      case 0:
        // deal
        if (handJSON.deal.length === 52) {
          isDisabled = false;
        }
        break;
      case 1:
        // auction
        if (!handJSON.playOnly &&
          hasEnded(handJSON.auction) &&
          (handJSON.auctionOnly || handJSON.dummy !== handJSON.viewpoint) &&
          striptags(handJSON.auctionIntro) !== ''
        ) {
          isDisabled = false;
        } else if (
          handJSON.playOnly &&
          handJSON.contract &&
          handJSON.contract.level &&
          handJSON.contract.denomination &&
          handJSON.declarer &&
          handJSON.dummy !== handJSON.viewpoint
        ) {
          isDisabled = false;
        }
        break;
      case 2:
        // play
        if ((handJSON.play.length === 13 &&
          handJSON.playIntro !== '') ||
          handJSON.auctionOnly
        ) {
          isDisabled = false;
        }
        break;
      case 3:
        // summary
        if (handJSON.summary !== '') {
          isDisabled = false;
        }
        break;
      default:
        isDisabled = true;
        break;
    }
    return isDisabled;
  };

  const handleSaveHand = (status, callback) => {
    setIsLoading(true);

    // if there is a status passed in, use it, else leave it as it was
    if (!status || status === undefined) {
      status = hand.status;
    }

    // set the hand json version number if its a new hand
    if (!handId) {
      handJSON.version = 'v1.1';
    }

    const variables = {
      id: handId,
      title: hand.title,
      status,
      handJSON: stripTypeNameFromMutationVars(handJSON)
    };

    if (!subscription) {
      variables.productId = parseInt(parent.id);
    } else {
      variables.articleId = parseInt(parent.id);
    }

    upsertHand({
      variables
    }).then(({ data }) => {
      // openSnackbarYouGit('Successfully saved', 'success');
      // set the id so we can update the record next time save is called
      if (callback) {
        callback();
      } else {
        setHandId(data.upsertHand.id);
      }
    }).catch(err => {
      const parsedError = parseError(err);
      openSnackbarYouGit('Error submitting form: ' + parsedError.message, 'error');
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const handleClosePreviewClick = () => {
    setPreviewOpen(false);
    setPreviewStartStep(null);
  };

  const handleDialogCloseClick = () => {
    setDialogOpen(false);
  };

  const handleDialogSubmitClick = () => {
    switch (activeStep) {
      case 0:
        // remove auction
        handJSON.auction = [];
        handJSON.contract = {};
      // no break so we can fall through
      case 1:
        // remove play
        handJSON.play = [];
        handJSON.tricks = {};
        break;
      default:
        break;
    }
    setHandJSON(handJSON);
    setDialogOpen(false);
    setStepProgress(activeStep);
  };

  const steps = getSteps();

  // preview expects a fully formed hand including its handJSON
  const previewHand = hand;
  previewHand.handJSON = handJSON;

  // console.log('=========> handJSON', handJSON);

  return (
    <div className={classes.root}>
      <MessageDialog
        dialogOpen={dialogOpen}
        onCloseClick={handleDialogCloseClick}
        onSubmitClick={handleDialogSubmitClick}
      />
      <Paper className={classes.paper}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => {
            let completed = false;
            let onClick = null;
            if (index <= stepProgress && index !== activeStep) {
              completed = true;
              onClick = handleStepClick(index);
            }
            return (
              <Step
                key={label}
                onClick={onClick}
                className={completed ? classes.step : null}
              >
                <StepLabel>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div className={classes.content}>
          <div style={{ width: '100%', border: 'solid red 0' }}>
            <Grid
              style={{ width: '100%', border: 'solid green 0' }}
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
            >
              <Grid item style={{ width: '100%', border: 'solid blue 0' }}>
                {getStepContent(activeStep)}
              </Grid>
            </Grid>
            <div className={classes.footer}>
              <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='flex-start'
              >
                <Grid item>
                  <Button
                    disabled={activeStep === 0}
                    variant='contained'
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleSaveAndExit}
                    className={classes.button}
                  >
                    Save and exit
                  </Button>
                </Grid>
                <Grid item>
                  <div>
                    <Button
                      disabled={!handJSON.auctionOnly && handJSON.play.length < 13}
                      variant='contained'
                      color='primary'
                      onClick={handlePreviewClick}
                      className={classes.button}
                    >
                      Preview
                    </Button>
                    <PreviewModal
                      open={previewOpen}
                      onCloseClick={handleClosePreviewClick}
                      hand={previewHand}
                      parent={parent}
                      subscription={subscription}
                      startStep={previewStartStep && 'play_' + previewStartStep}
                    />
                    <div className={classes.submit}>
                      {
                        hand.status !== 'Draft'
                          ? <BBSubmit
                              label={
                                activeStep < 3
                                  ? 'Save and continue'
                                  : 'Save'
                              }
                              disabled={nextDisabled()}
                              isLoading={isLoading}
                              onClick={activeStep < 3 ? handleNext : handleSaveAndExit}
                            />
                          : <BBSubmit
                              label={
                                activeStep < 3
                                  ? 'Save and continue'
                                  : 'Send for approval'
                              }
                              disabled={nextDisabled()}
                              isLoading={isLoading}
                              onClick={activeStep < 3 ? handleNext : handleSendForApproval}
                            />
                      }
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

HandForm.propTypes = {
  hand: PropTypes.object,
  upsertHand: PropTypes.func.isRequired,
  parent: PropTypes.object,
  subscription: PropTypes.object,
  openSnackbarYouGit: PropTypes.func.isRequired
};

export default HandForm;
