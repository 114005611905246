import gql from 'graphql-tag';

const articleFragments = {
  fullArticle: gql`
    fragment FullArticle on Article {
      id
      publicId
      title
      body
      summary
      status
      publishDateTime
      publishDateTimeUniversal
      subscription {
        id
        title
        images
        onSale
        tagsCache
        author {
          id
          fullName
          avatarPath
        }
      }
      hands {
        id
        title
        canPlay
        freeToPlay
      }
    }
  `,
  partialArticle: gql`
    fragment PartialArticle on Article {
      id
      publicId
      title
      body
      summary
      status
      freeToRead
      publishDateTime
      publishDateTimeUniversal
      hands {
        id
        title
      }
    }
  `
};

export default articleFragments;