import gql from 'graphql-tag';
import {
  articleFragments,
  subscriptionFragments
} from '../fragments';

const subscriptionsQueries = {
  Subscriptions: gql`
    query {
      subscriptions {
        id
        title
        images
        tagsCache
        description
        priceLocal
        priceLocalFormatted
        onSale
        author {
          id
          fullName
          avatarPath
        }
      }
    }
  `,
  SubscriptionsAdmin: gql`
    query {
      subscriptionsAdmin {
        id
        title
        onSale
        author {
          id
          fullName
        }
      }
    }
  `,
  SubscriptionSearch: (options) => {
    return gql`
      query {
        subscriptionSearch (
          input: {
            searchTerm: "${options.searchTerm}"
            limit: ${options.limit}
            offset: ${options.offset}
            orderBy: "${options.orderBy}"
            orderByDirection: "${options.orderByDirection}"
          }
        ) {
          count
          subscriptions {
            id
            title
            onSale
            description
            author {
              id
              fullName
            }
          }
        }
      }
    `;
  },
  SubscriptionById: (id) => {
    return gql`
      query {
        subscriptionById (
          id: ${id}
        ) {
          ...FullSubscription
          freeArticles {
            ...PartialArticle
          }
        }
      }
      ${subscriptionFragments.fullSubscription}
      ${articleFragments.partialArticle}
    `;
  },
  SubscriptionByIdAdmin: (id) => {
    return gql`
      query {
        subscriptionByIdAdmin (
          id: ${id}
        ) {
          ...FullSubscription
          articles {
            ...PartialArticle
          }
        }
      }
      ${subscriptionFragments.fullSubscription}
      ${articleFragments.partialArticle}
    `;
  }
};

export default subscriptionsQueries;
