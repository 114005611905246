import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Outlet } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { client } from './graphql';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './mui/theme.js';
import {
  CartProvider,
  DrawerProvider,
  GiftProvider,
  HandProvider,
  LocaleProvider,
  UserProvider
} from './Context';
import CurrentUser from './Components/Queries/CurrentUser.js';
import BBErrorBoundary from './Components/Errors/BBErrorBoundary.js';
import { BBSnackbarProvider } from './Components/BBSnackbar/BBSnackbarContext.js';
import RefreshAfterDeploy from './Components/RefreshAfterDeploy.js';
import DeviceDetector from './Components/DeviceDetector.js';
import RobotsMetaTag from './Components/RobotsMetaTag.js';
import Analytics from './Components/Analytics.js';
import Drawer from './Components/Drawer/Drawer.js';
import ScrollToTop from './Components/ScrollToTop';
import CardSVGPreloader from './Components/Hands/Cards/CardSVGPreloader.js';

const App = () => {
  return (
    <ApolloProvider client={client}>
      <BBErrorBoundary>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <RobotsMetaTag />
            <ScrollToTop />
            <RefreshAfterDeploy />
            <DeviceDetector>
              <BBSnackbarProvider>
                <Analytics>
                  <CurrentUser>
                    {currentUser => (
                      <UserProvider currentUser={currentUser}>
                        <LocaleProvider>
                          <DrawerProvider>
                            <CartProvider>
                              <GiftProvider>
                                <HandProvider>
                                  <CardSVGPreloader />
                                  <Drawer />
                                  <Outlet />
                                </HandProvider>
                              </GiftProvider>
                            </CartProvider>
                          </DrawerProvider>
                        </LocaleProvider>
                      </UserProvider>
                    )}
                  </CurrentUser>
                </Analytics>
              </BBSnackbarProvider>
            </DeviceDetector>
          </ThemeProvider>
        </StyledEngineProvider>
      </BBErrorBoundary>
    </ApolloProvider>
  );
};

export default App;
