import gql from 'graphql-tag';

const authorQueries = {
  // AUTHORS
  Authors: gql`
    query {
      authors {
        id
        firstName
        lastName
        fullName
        fullNameAdmin
        avatarPath
        bio
        language
        products {
          id
          type
        }
      }
    }
  `,
  AuthorSearch: (options) => {
    return gql`
      query {
        authorSearch (
          input: {
            searchTerm: "${options.searchTerm}"
            limit: ${options.limit}
            offset: ${options.offset}
            orderBy: "${options.orderBy}"
            orderByDirection: "${options.orderByDirection}"
          }
        ) {
          count
          authors {
            id
            firstName
            lastName
            fullName
            fullNameAdmin
            dateCreated
          }
        }
      }
    `;
  },
  AuthorById: (id, currency) => {
    return gql`
      query {
        authorById (
          id: ${id},
          locale: {
            currency: ${currency}
          }
        ) {
          id
          firstName
          lastName
          fullName
          fullNameAdmin
          bio
          avatarPath
          backgroundImagePath
          dateCreated
          royaltyPercentage
          language
          authorLinks {
            id
            linkUrl
            linkType
          }
          products {
            id
            type
          }
        }
      }
    `;
  }
};

export default authorQueries;
