import React from 'react';
import { Grid } from '@mui/material';
import StoreSectionContainer from '../../Components/Store/StoreSectionContainer.js';
import SubscriptionItemLarge from './Components/SubscriptionItemLarge.js';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { subscriptionQueries } from '../../graphql/queries';

const FeaturedSubscriptions = () => {
  const { t } = useTranslation();
  const { data } = useQuery(subscriptionQueries.Subscriptions);
  const navigate = useNavigate();

  if (!data) {
    return null;
  }

  let subscriptions = data.subscriptions || [];

  subscriptions = subscriptions.slice(0, 2);

  return (
    <StoreSectionContainer
      heading={t('our-subscriptions')}
      subheading={t('whether-youve-been-playing-or-getting-started')}
      ctaButtonLabel='view-all'
      onCtaButtonClick={() => { navigate('/products/all?Purchase+type=Subscription'); }}
      extraWhitespace
    >
      <Grid container spacing={3}>
        {
          subscriptions.map(subscription => (
            <Grid
              item
              xs={12}
              sm={6}
              key={subscription.id}
            >
              <SubscriptionItemLarge
                key={subscription.id}
                product={subscription}
              />
            </Grid>
          ))
        }
      </Grid>
    </StoreSectionContainer>
  );
};

export default FeaturedSubscriptions;
