import gql from 'graphql-tag';

const subscriptionPlanFragments = {
  fullSubscriptionPlan: gql`
    fragment FullSubscriptionPlan on SubscriptionPlan {
      id
      type
      price {
        id
        priceUSD
        priceGBP
        priceEUR
        priceCAD
        priceAUD
        priceNZD
      }
    }
  `
};

export default subscriptionPlanFragments;