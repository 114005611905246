import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import WaitingMessage from './WaitingMessage.js';
import MessageWrapper from './MessageWrapper.js';

export default function Message ({ children, position, waitingMessageDelay }) {
  const [waiting, setWaiting] = useState(true);

  let messagesEnd = React.createRef()

  // https://stackoverflow.com/questions/37620694/how-to-scroll-to-bottom-in-react
  const scrollToBottom = () => {
    if (messagesEnd) {
      messagesEnd.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleTimeoutComplete = () => {
    setWaiting(false);
    scrollToBottom();
  };

  useEffect(() => {
    scrollToBottom();
  });

  return (
    <MessageWrapper
      position={position}
    >
      {
        waiting && position === 'left'
        ?
        <WaitingMessage
          delayInMS={waitingMessageDelay}
          position={position}
          onTimeoutComplete={handleTimeoutComplete}
        />
        :
        children
      }
      <div ref={(el) => { messagesEnd = el; }}></div>
    </MessageWrapper>
  );
};

Message.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.string.isRequired,
  waitingMessageDelay: PropTypes.number.isRequired
};

