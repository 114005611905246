import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  enabled: {
    cursor: 'pointer'
  },
  disabled: {
    filter: 'brightness(80%)', // <--- instead of card overlay (overlay removed CardSuitGroup)
    cursor: 'not-allowed',
    '&:active': {
      animation: '$shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
      transform: 'translate3d(0, 0, 0)',
      backfaceVisibility: 'hidden'
    }
  },
  '@keyframes shake': {
    '10%, 90%': {
      transform: 'translate3d(-1px, 0, 0)'
    },
    '20%, 80%': {
      transform: 'translate3d(2px, 0, 0)'
    },
    '30%, 50%, 70%': {
      transform: 'translate3d(-4px, 0, 0)'
    },
    '40%, 60%': {
      transform: 'translate3d(4px, 0, 0)'
    }
  }
}));

const Card = (props) => {
  const {
    value,
    flipped,
    enabled,
    onClick,
    cardRefCallback,
    variant = 'normal'
  } = props;
  
  const classes = useStyles();

  let src = `/img/cards/${variant}/${value}.png`;
  if (flipped) {
    src = `/img/cards/${variant}/BACK.png`;
  }

  return (
    <img
      className={clsx(enabled && !flipped ? classes.enabled : classes.disabled, classes.shake)}
      style={{ width: '100%' }}
      src={src}
      alt={value}
      onClick={() => {
        if (enabled && onClick) {
          onClick(value);
        }
      }}
      ref={cardRefCallback}
    />
  );
};

Card.propTypes = {
  value: PropTypes.string.isRequired,
  flipped: PropTypes.bool,
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
  cardRefCallback: PropTypes.func
};

export default Card;
