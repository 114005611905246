import React, { useState, useCallback, useEffect, useContext } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Grid,
  Card,
  CardContent,
  FormControl,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import DynamoProductSearch from '../../../../Components/Queries/DynamoProductSearch.js';
import SearchResultsSkeleton from '../../../../Components/Skeletons/SearchResultsSkeleton.js';
import ListView from '../../../../Components/Store/ListView.js';
import StoreButton from '../../../../Components/Forms/Store/StoreButton.js';
import StoreSelect from '../../../../Components/Forms/Store/StoreSelect.js';
import BBDrawer from '../../../../Components/Drawer/BBDrawer.js';
import LoadMoreScrollAnchor from '../../../../Components/LoadMoreScrollAnchor.js';
import { convertLangCodeToGraphqlEnum } from '../../../../helpers/locale.js';
import ProductFilterPicker from '../../../../Components/Store/ProductFilterPicker/ProductFilterPicker.js';
import FilterButton from '../../../../Components/Store/ProductFilterPicker/FilterButton.js';
import BBTranslate from '../../../../Components/Locale/BBTranslate.js';
import { LocaleContext } from '../../../../Context';
import {
  getSelectedTagsFromSearchParams,
  getSearchParamsFromSelectedTags
} from '../../../../helpers/productTagFilter.js';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '3rem',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column-reverse'
    }
  },
  sortAndFilter: {
    position: 'absolute',
    top: 0,
    right: 0,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      display: 'flex',
      marginBottom: '2rem',
      '& .MuiFormControl-root': {
        width: 'auto'
      }
    }
  },
  filterByButton: {
    marginLeft: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1
    }
  },
  filterContainer: {
    boxShadow: theme.boxShadow,
    marginRight: '2rem'
  },
  updateButton: {
    position: 'sticky',
    width: '100%',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '1rem 1rem 2rem 1rem',
    '& .MuiButton-root': {
      width: '100%'
    }
  },
  loadMore: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      '& button': {
        width: '100%'
      }
    }
  },
  noProducts: {
    textAlign: 'center',
    marginTop: '3rem'
  }
}));

const AllProducts = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState('id');

  const { locale } = useContext(LocaleContext);

  const [contentLanguage, setContentLanguage] = useState('');

  const [selectedTagsByGroup, setSelectedTagsByGroup] = useState(getSelectedTagsFromSearchParams(searchParams));

  const theme = useTheme();
  const filtersInDrawer = useMediaQuery(theme.breakpoints.down('md'));
  const [filtersOpen, setFiltersOpen] = useState(false);

  const handleSelectedTagsChange = (groupName, clickedTag, checked) => {

    setSelectedTagsByGroup((prevTags) => {
      const nextTags = { ...prevTags };
      let nextGroupTags = [...nextTags[groupName]];
      
      if (checked) {
        nextGroupTags.push(clickedTag);
      } else {
        const idx = nextGroupTags.findIndex(tag => tag === clickedTag);
        nextGroupTags.splice(idx, 1);
      }

      // we no longer have 'Any system' as a filter option but instead
      // we want to get any products marked as 'Any sytem' to be returned 
      // in any system tag filter - #251
      if (groupName === 'System' && nextGroupTags.length === 1) {
        if (checked) {
          // add 'Any System'
          nextGroupTags.push('Any system');
        } else {
          // remove 'Any System'
          nextGroupTags = [];
        }
      }

      nextTags[groupName] = nextGroupTags;

      return nextTags;
    });
  };

  const handleOrderByChange = useCallback((e) => {
    setOrderBy(e.target.value);
  }, [setOrderBy]);

  const handleContentLanguageChange = useCallback((language) => {
    setContentLanguage(language);
  }, [setContentLanguage]);

  useEffect(() => {
    const nextSearchParams = getSearchParamsFromSelectedTags(selectedTagsByGroup);

    // only set the contentLanguage when the page loads
    if (!contentLanguage) {
      setContentLanguage(locale.language);
    }

    setSearchParams(nextSearchParams, {
      // without this the back button gets hijacked
      // whenever the AllProducts view is opened
      replace: true
    });
  }, [setSearchParams, selectedTagsByGroup, locale, contentLanguage]);

  if (!locale || !contentLanguage) {
    return <SearchResultsSkeleton />;
  }

  return (
    <div>
      <div className={classes.header}>
        <Typography variant='h1'>
          <BBTranslate textId='all-products' />
        </Typography>
        <div className={classes.sortAndFilter}>
          <FormControl size='small'>
            <StoreSelect
              value={orderBy}
              options={[
                { id: 'id', name: 'published-date-newest-first' },
                { id: 'title', name: 'product-title-az' },
                { id: 'author', name: 'author-surname-az' },
                { id: 'price', name: 'price-low-to-high' }
              ]}
              onChange={handleOrderByChange}
            />
          </FormControl>
          {filtersInDrawer && (
            <div className={classes.filterByButton}>
              <FilterButton
                selectedTagsByGroup={selectedTagsByGroup}
                onClick={() => setFiltersOpen(true)}
              />
              <BBDrawer
                open={filtersOpen}
                onClose={() => setFiltersOpen(false)}
                heading='filter'
              >
                <ProductFilterPicker
                  onContentLanguageChange={handleContentLanguageChange}
                  selectedTagsByGroup={selectedTagsByGroup}
                  onTagsChange={handleSelectedTagsChange}
                  locale={locale}
                  contentLanguage={contentLanguage}
                />
                {/*
                  doesn't quite do what it says on the tin
                  but provides a useful CTA for mobile users
                */}
                <div className={classes.updateButton}>
                  <StoreButton
                    label='Update'
                    onClick={() => setFiltersOpen(false)}
                  />
                </div>
              </BBDrawer>
            </div>
          )}
        </div>
      </div>
      <Grid container>
        {!filtersInDrawer && (
          <Grid item md={3}>
            <Card className={classes.filterContainer}>
              <CardContent>
                <ProductFilterPicker
                  onContentLanguageChange={handleContentLanguageChange}
                  selectedTagsByGroup={selectedTagsByGroup}
                  onTagsChange={handleSelectedTagsChange}
                  locale={locale}
                  contentLanguage={contentLanguage}
                />
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={12} md={9}>
          <DynamoProductSearch
            options={{
              orderBy,
              // order in ascending order unless nothing ("id") is picked
              // in which case this shows latest products first
              orderByDirection: orderBy === 'id' ? 'desc' : 'asc',
              tags: Object.values(selectedTagsByGroup),
              pagination: {
                pageSize: 10
              },
              language: convertLangCodeToGraphqlEnum(contentLanguage),
              currency: locale.currency
            }}
          >
            {({ products, loadMore, loading }) => (
              <>
                {
                  loading
                    ? (
                      <SearchResultsSkeleton />
                      )
                    : products && products.length > 0
                      ? (
                        <ListView
                          products={products}
                        />
                        )
                      : (
                        <Typography className={classes.noProducts}>
                          <BBTranslate textId='no-matching-products-found' />.
                        </Typography>
                        )
                }
                {
                  loadMore && (
                    <LoadMoreScrollAnchor loadMore={loadMore} key={products && products.length} />
                  )
                }
              </>
            )}
          </DynamoProductSearch>
        </Grid>
      </Grid>
    </div>
  );
};

export default AllProducts;
