import React from 'react';
import HighlightSection from '../../Components/Sales/HighlightSection.js';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: '74rem',
    overflow: 'hidden',
    backgroundColor: '#534a45',
    // makes the BG on the text container extend
    // to the right edge of the viewport
    '&:after': {
      content: '" "',
      position: 'absolute',
      right: 0,
      left: 'calc(50% + 59rem)',
      top: 0,
      bottom: 0,
      backgroundColor: '#333'
    },
    [theme.breakpoints.down('md')]: {
      height: '56rem',
      '&:after': {
        content: 'none'
      }
    }
  },
  contentContainer: {
    maxWidth: '118rem',
    margin: '0 auto',
    zIndex: 1,
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  image: {
    backgroundImage: 'url(/img/components/sales/hand-iphone-2.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'left top',
    height: '100%',
    position: 'absolute',
    width: 1000,
    right: '25%',
    // this allows us to fade out the left-hand side of the image
    // so there's no clean edge
    maskImage: 'linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 1) 10%)',
    [theme.breakpoints.down('lg')]: {
      right: '13%'
    },
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      right: 0,
      width: '100%',
      maskImage: 'none',
      backgroundPosition: '0 center'
    }
  },
  textContainer: {
    position: 'absolute',
    right: 0,
    left: '60%',
    height: '100%',
    display: 'flex',
    padding: '5rem 1rem 5rem 5rem',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#333',
    // slight shadow fade at the left edge of the copy section
    '&:before': {
      content: '" "',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: 15,
      backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0))'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '50%',
      left: 0,
      position: 'relative',
      padding: '4rem 2rem',
      '&:before': {
        content: 'none'
      }
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    }
  },
}));

const Highlight1 = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.image} />
        <div className={classes.textContainer}>
          <HighlightSection
            heading={t('save-10-pct-subscriptions')}
            body={t('we-have-the-most-engaging-bridge-teachers')}
          />
        </div>
      </div>
    </div>
  );
};

export default Highlight1;
