import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import MessageGroup from './MessageGroup.js';

const useStyles = makeStyles({
  messages: {
    overflow: 'hidden',
    overflowY: 'scroll',
    padding: '2rem',
    borderLeft: 'none',
    borderRight: 'none'
  }
});

export default function Chat ({ conversation, waitingMessageDelay }) {
  const classes = useStyles();
  return (
    <div className={classes.messages}>
      {
        conversation.map((messageGroup, index) => {
          return (
            <MessageGroup
              key={index}
              messages={messageGroup.messages}
              position={messageGroup.position}
              waitingMessageDelay={waitingMessageDelay}
            />
          );
        })
      }
    </div>
  );
}

Chat.propTypes = {
  conversation: PropTypes.array.isRequired,
  waitingMessageDelay: PropTypes.number.isRequired
};
