import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import Typography from '@mui/material/Typography';
import PurchaseTable from '../../../Components/Store/Purchases/PurchaseTable.js';
import Button from '@mui/material/Button';
import BBInput from '../../../Components/Forms/BBInput.js';
import BBAdminForm from '../../../Components/Forms/BBAdminForm.js';
import BBSelect from '../../../Components/Forms/BBSelect.js';
import { persistJwt } from '../../../helpers/auth.js';
import { 
  manageLocaleCookie,
  convertGraphQLLanguageName
} from '../../../helpers/locale.js';
import UserPurchases from '../../../Components/Queries/UserPurchases.js';


const AdminPurchases = ({ user }) => (
  <UserPurchases userId={user.id}>
    {userPurchases => (
      <PurchaseTable userPurchases={userPurchases}/>
    )}
  </UserPurchases>
)

const UserForm = (props) => {
  const { typeEnum, user, updateUser, impersonateUser, ...other } = props;

  const navigate = useNavigate();

  const handleImpersonateClick = () => {
    impersonateUser({
      variables: { userId: user.id }
    }).then((response) => {
      if (response.data && response.data.impersonateUser) {
        const impersonateUser = response.data.impersonateUser;
        persistJwt(impersonateUser.token);
        manageLocaleCookie('write', { 
          language: convertGraphQLLanguageName(user.language),
          currency: user.currency
        });
        navigate('/impersonate?return=users');
      } else {
        alert('User cannot be found');
      }
    }).catch(err => {
      alert(`error: ${err.message}`);
    });
  };

  return (
    <BBAdminForm
      fields={user}
      onSubmit={updateUser}
      {...other}
    >
      <BBInput
        id='firstName'
        name='firstName'
        type='text'
        label='First name'
        autoComplete='given-name'
        autoFocus
        required
        maxLength={100}
        validate={(val) => (val ? '' : 'First name is required')}
      />
      <BBInput
        id='lastName'
        name='lastName'
        type='text'
        label='Last name'
        autoComplete='family-name'
        required
        maxLength={100}
        validate={(val) => (val ? '' : 'Last name is required')}
      />
      <BBInput
        id='email'
        name='email'
        type='email'
        label='Email address'
        autoComplete='email'
        required
        maxLength={100}
        validate={(val) => (validator.isEmail(val) ? '' : 'Invalid Email')}
      />
      <BBSelect
        id='type'
        name='type'
        label='User Type'
        valueField='name'
        keyField='name'
        width={100}
        items={typeEnum}
      />
      <Button variant='outlined' id='button' onClick={handleImpersonateClick} color='primary'>
        Impersonate
      </Button>
      <div id='history' style={{ overflow: 'scroll', height: 200, margin: 0 }}>
        <Typography variant='subtitle2' gutterBottom>
          Purchase history:
        </Typography>
        <AdminPurchases user={user} />
      </div>
    </BBAdminForm>
  );
};

UserForm.propTypes = {
  updateUser: PropTypes.func.isRequired,
  impersonateUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  typeEnum: PropTypes.array.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  onSubmitError: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired
};

export default UserForm;
