import React from 'react';
import PropTypes from 'prop-types';
import {
  TreeView,
  TreeItem
} from '@mui/lab';
import {
  FormControlLabel,
  Checkbox
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const HandsTree = ({ productData, selected, onCheckboxClick }) => {
  const renderTree = (node) => {
    return (
      <TreeItem
        key={node.id}
        nodeId={node.id.toString()}
        label={
          node.type === 'hand'
            ? <FormControlLabel
                control={
                  <Checkbox
                    checked={selected.some(id => `hand-${id}` === node.id)}
                    onChange={e => onCheckboxClick(e.currentTarget.checked, node)}
                    onClick={e => e.stopPropagation()}
                  />
                }
                label={node.label}
              />
            : node.label
        }
      >
        {
          Array.isArray(node.children)
            ? node.children.map(n => renderTree(n))
            : null
        }
      </TreeItem>
    );
  };

  if (!productData) {
    return null;
  }

  return (
    <>
      <TreeView
        disableSelection
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultExpanded={['0']}
        style={{
          width: '100%',
          height: 400,
          overflow: 'scroll'
        }}
      >
        {renderTree(productData)}
      </TreeView>
    </>
  );
};

HandsTree.propTypes = {
  productData: PropTypes.object,
  selected: PropTypes.array,
  onCheckboxClick: PropTypes.func.isRequired
};

export default HandsTree;
