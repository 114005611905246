import { formatURLTitle }  from './http.js';

const buildInternalSubscriptionUrl = (subscription) => {
  // check if they are subscribed, if they are just send em to the article page 
  // but don't include an article id so they just get the latest article they have
  // subscribed to
  if (subscription.subscribed || subscription.expired) {
    return buildInternalArticleUrl(null, subscription);
  }
  // subscriptionId/:subscriptionTitle
  return `/subscriptions/${subscription.id}/${formatURLTitle(subscription.title)}`;
};

const buildInternalArticleUrl = (article, subscription) => {
  if (article) {
    // subscriptionId/:subscriptionTitle/articles/:articlePublicId/:articleTitle
    return `/subscriptions/${subscription.id}/${formatURLTitle(subscription.title)}/articles/${article.publicId}/${formatURLTitle(article.title)}`;
  }
  // subscriptionId/:subscriptionTitle/articles
  return `/subscriptions/${subscription.id}/${formatURLTitle(subscription.title)}/articles`;
};

const isActiveSubscription = (user, subscriptionId) => {
  let isActive = false;
  if (user.activeSubscriptions && user.activeSubscriptions.length > 0) {
    const activeSubscriptionId = user.activeSubscriptions.find(as => as === subscriptionId);
    isActive = activeSubscriptionId !== undefined;
  }
  return isActive;
};

const hasExpiredSubscription = (user, subscriptionId) => {
  let hasExpired = false;
  if (user.expiredSubscriptions && user.expiredSubscriptions.length > 0) {
    const expiredSubscriptionId = user.expiredSubscriptions.find(es => es === subscriptionId);
    hasExpired = expiredSubscriptionId !== undefined;
  }
  return hasExpired;
};

const getPlanByType = (subscription, planType) => {
  const plan = { ...subscription.subscriptionPlans.find(plan => plan.type.toLowerCase() === planType) };
  plan.subscription = subscription;
  return plan;
}

export {
  buildInternalSubscriptionUrl,
  buildInternalArticleUrl,
  isActiveSubscription,
  hasExpiredSubscription,
  getPlanByType
}