import { createTheme } from '@mui/material/styles';
import green from '@mui/material/colors/green';

/*
  Bridgbee colour palette:

  blue: #013398
  green: #437561
  yellow: #FFB73A
  purple: #683044
  teal: #80BCB2
  red: #C03D36
  cream: #F4ECDC
  lilac: #BA8098

*/

let theme = createTheme();

// overriding defaults: https://material-ui.com/customization/default-theme/
theme = createTheme({
  typography: {
    fontFamily: '"Inter", "helvetica neue", helvetica, arial, sans-serif',
    useNextVariants: true,
    htmlFontSize: 10,
    fontSize: 16,
    brandFont: {
      fontFamily: 'roc-grotesk, sans-serif',
      fontStyle: 'normal',
      fontWeight: 600
    },
    h1: {
      fontSize: 30,
      color: '#683044',
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        fontSize: 26
      }
    },
    h2: {
      fontSize: 24,
      color: '#683044',
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        fontSize: 20
      }
    },
    h3: {
      fontSize: 20,
      color: '#683044',
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        fontSize: 16
      }
    },
    h4: {
      fontSize: 16,
      color: '#683044',
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        fontSize: 14
      }
    },
    h5: {
      fontSize: 14,
      color: '#683044',
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        fontSize: 14
      }
    },
    // body1 and body2 are the same on purpose
    // material seems to use both of them under the hood for unrelated components
    // which we want to look exactly the same
    body1: {
      fontSize: 16,
      color: '#595959',
      fontWeight: 400,
      fontFamily: 'Roboto, sans-serif',
      [theme.breakpoints.down('md')]: {
        fontSize: 16
      }
    },
    body2: {
      fontSize: 16,
      color: '#595959',
      fontWeight: 400,
      fontFamily: 'Roboto, sans-serif',
      [theme.breakpoints.down('md')]: {
        fontSize: 14
      }
    },
    // body3 and body4 are used by our app for non-default styles
    body3: {
      fontSize: 14,
      color: '#595959',
      fontWeight: 400,
      fontFamily: 'Roboto, sans-serif',
      [theme.breakpoints.down('md')]: {
        fontSize: 14
      }
    },
    body4: {
      fontSize: 20,
      color: '#595959',
      fontWeight: 400,
      fontFamily: 'Roboto, sans-serif'
    },
    button: {
      textTransform: 'none'
    }
  },
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
  palette: {
    primary: {
      light: '#865969',
      main: '#683044',
      dark: '#532636'
    },
    secondary: {
      light: '#568371',
      main: '#437561',
      dark: '#396352'
    },
    error: {
      light: '#CC635E',
      main: '#C03D36',
      dark: '#A3332D'
    },
    // adding a colour into the pallette allows the colour to be used in a components color prop
    // e.g. <Button color='blue' /> 
    blue: {
      main: '#133293',
      contrastText: '#ffffff'
    },
    white: {
      main: '#fff',
      contrastText: '#eee'
    },
    pink: {
      main: '#DF7D68',
      contrastText: '#fff'
    },
    styleguide: {
      beige: '#f5f2f2',
      maroon: '#683044',
      yellow: '#FFB73A',
      blue: '#013398',
      green: '#437561',
      red: '#C03D36',
      pink: '#DF7D68',
      teal: '#8DBAB2',
      beigeDark: '#DBD3C5',
      blueDark: '#00287A',
      maroonDark: '#4D2332'
    },
    common: {
      pink: '#DF7D68',
      grey: '#BFBFBF',
      lightGrey: '#DDDDDD',
      oatmeal: '#F3ECDE'
    },
    background: {
      default: '#f5f2f2'
    },
    success: {
      main: green[500]
    },
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  },
  shape: {
    // borderRadius: '.2rem'
  },
  form: {
    input: {
      borderRadius: '.2rem'
    }
  },
  extendableBackground: {
    // the following lines allow this element's background colour
    // to extend outside of the fixed width content container
    // but have the content fit within the fixed width
    //
    // first, make the element take up the full width of the viewport
    // (incl. padding)
    width: '100vw',
    // shift the element left by the amount of distance that's left between
    // the left edge of the container and the viewport
    // % refers to container width, vw refers to viewport width
    //
    // so e.g. if container is 1180px and screen is 1600px
    // we want to move this element 210px to the left,
    // giving left of : (1180/2) - (1600/2) -> -210px
    position: 'relative',
    left: 'calc(50% - 50vw)',
    // add padding on either side to compensate for the movement
    // (210px on each side in the example above)
    paddingLeft: 'calc(50vw - 50%)',
    paddingRight: 'calc(50vw - 50%)'
  },
  shadows: Array(25).fill('none'),
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          fontSize: '10px',
          height: '100%'
        },
        body: {
          padding: 0
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          p: {
            color: '#fff',
            fontSize: '1.6rem'
          }
        }
      }
    },
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true // No more ripple, on the whole application 💣!
        // textTransform: 'none'
      }
    }
  }
});

export default theme;
