import React from 'react';
import { useTheme } from '@mui/styles';
import FeaturedAuthorsShared from '../../Components/Store/FeaturedAuthors/FeaturedAuthors.js';

const FeaturedAuthors = () => {
  const theme = useTheme();
  return (
    <FeaturedAuthorsShared
      backgroundColor={theme.palette.common.oatmeal}
      extraWhitespace
    />
  );
};

export default FeaturedAuthors;
