import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Auction from '../../../../Components/Hands/Auction.js';

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 2
  },
  content: {
    padding: '3rem',
    fontSize: '.6em'
  }
});

export default function AuctionModal ({ open, onCloseClick, dealer, auction }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onCloseClick}
    >
      <div>
        <IconButton
          aria-label='Close'
          className={classes.closeButton}
          onClick={onCloseClick}
          size='large'
        >
          <CloseIcon />
        </IconButton>
        <div className={classes.content}>
          <Auction
            dealer={dealer}
            auction={auction}
          />
        </div>
      </div>
    </Dialog>
  );
};

AuctionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  dealer: PropTypes.string.isRequired,
  auction: PropTypes.array.isRequired
};
