import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTextTranslation } from '../../../Hooks';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: '1.6rem',
    padding: '.8rem 1.6rem',
    textTransform: 'none',
    borderRadius: '0.7rem',
    textDecoration: 'none',
    zIndex: 1
  },
  primary: {
    background: theme.palette.styleguide.yellow,
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      background: '#f09e15'
    }
  },
  secondary: {
    background: theme.palette.styleguide.beige,
    color: theme.palette.styleguide.maroon,
    borderColor: theme.palette.styleguide.maroon,
    borderStyle: 'solid',
    borderWidth: 1,
    '&:hover': {
      background: theme.palette.common.lightGrey
    }
  },
  warning: {
    background: theme.palette.styleguide.red,
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      background: '#802924'
    }
  },
  disabled: {
    backgroundColor: '#ccc !important'
  }
}));

const StoreButton = ({ label, disabled, loading, onClick, variant, color, fullWidth, type }) => {
  const classes = useStyles();
  const translatedLabel = useTextTranslation(label);

  return (
    <Button
      type={type ? type : 'button'}
      className={clsx(classes.button, classes[color || 'primary'], disabled && classes.disabled)}
      variant={variant || 'contained'}
      onClick={onClick}
      fullWidth={fullWidth}
      disabled={disabled || loading}
    >
      {loading
        ? <CircularProgress size={24} />
        : translatedLabel}
    </Button>
  );
};

StoreButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.string
};

export default StoreButton;
