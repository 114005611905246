import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%'
  },
  contentContainer: {
    display: 'flex',
    height: '100%',
    position: 'relative',
    maxWidth: '118rem',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse'
    }
  },
  textContainer: {
    width: '522px',
    maxWidth: '50%',
    padding: '2rem',
    zIndex: 1,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      margin: '0 auto',
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'normal',
      justifyContent: 'normal',
      textAlign: 'left'
    }
  },
  image: {
    position: 'absolute',
    right: '-10%',
    bottom: 0,
    top: 0,
    width: '60%',
    // this allows us to fade out the right-hand side of the image
    // so there's no clean edge
    maskImage: 'linear-gradient(to left, transparent 0%, rgba(0, 0, 0, 1) 10%)',
    [theme.breakpoints.down('md')]: {
      right: 0,
      width: '75%',
      position: 'relative',
      flexShrink: 1,
      margin: '0 auto'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maskImage: 'unset'
    }
  },
  heading: {
    fontSize: 48,
    marginBottom: '3rem',
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      marginBottom: '1.6rem'
    }
  },
  body: {
    color: '#683044'
  },
  button: {
    marginTop: '3rem',
    [theme.breakpoints.down('md')]: {
      margin: '3rem auto 0'
    },
    [theme.breakpoints.down('sm')]: {
      margin: '3rem 0 0',
      '& button': {
        width: '100%'
      }
    }
  }
}));

const Hero = (props) => {
  const { title, body, imageSrc, button } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.textContainer}>
          <Typography
            variant='h1'
            className={classes.heading}
          >
            {title}
          </Typography>
          <Typography
            variant='body4'
            className={classes.body}
          >
            {body}
          </Typography>
          {button && (
            <div
              className={classes.button}
            >
              {button}
            </div>
          )}
        </div>
        <img
          alt=''
          className={classes.image}
          src={imageSrc}
        />
      </div>
    </div>
  );
};

export default Hero;
