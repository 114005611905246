import _ from 'lodash';
import {
  isActiveSubscription,
  hasExpiredSubscription
} from './subscription';

export const hasPurchasedProduct = (productId, purchasedProducts) => {
  const purchasedProduct = _.find(purchasedProducts, (p) => {
    return p.id === productId;
  });
  return purchasedProduct !== undefined;
};

export const markPurchasedProducts = (products, user) => {
  if (user) {
    return products.map(product => ({
      ...product,
      purchased: hasPurchasedProduct(product.id, user.products),
      subscribed: isActiveSubscription(user, product.id),
      expired: hasExpiredSubscription(user, product.id)
    }));
  }
  return products;
};

export const getProductListFromPurchases = (purchases) => {
  const products = [];
  purchases.forEach(purchase => {
    purchase.purchaseItems.forEach(item => {
      if (item.product && !item.giftedToUserId) {
        products.push({ ...item.product, purchased: true });
      }
    });
  });
  return products;
};

export const formatPurchasesAndSubscriptionsForLibrary = (user, subscriptions) => {
  const libraryItems = [];
  // first add the active subscriptions
  if (user.activeSubscriptions && user.activeSubscriptions.length > 0) {
    const activeSubscriptions = user.activeSubscriptions.map(subscriptionId => {
      const subscription = subscriptions.find(s => s.id === subscriptionId);
      return { ...subscription, productType: 'Subscription', subscribed: true };
    });
    libraryItems.push(...activeSubscriptions);
  }
  // second add the expired subscriptions
  if (user.expiredSubscriptions && user.expiredSubscriptions.length > 0) {
    const expiredSubscriptions = user.expiredSubscriptions.map(subscriptionId => {
      const subscription = subscriptions.find(s => s.id === subscriptionId);
      return { ...subscription, productType: 'Subscription',  expired: true };
    })
    libraryItems.push(...expiredSubscriptions);
  }
  // third add the products
  const products = getProductListFromPurchases(user.purchases);
  libraryItems.push(...products);

  return libraryItems;
};