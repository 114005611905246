import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  useMediaQuery
} from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import Truncate from 'react-text-truncate';
import Tags from './Tags.js';
import StoreButton from '../Forms/Store/StoreButton.js';
import AuthorLink from './AuthorLink.js';
import Price from './Price.js';
import SubscriptionChip from './Subscriptions/SubscriptionChip.js';
import { getGroupTagsFromTagsCache } from '../../helpers/tag.js';
import { buildInternalProductUrl } from '../../helpers/product.js';
import { buildInternalSubscriptionUrl } from '../../helpers/subscription.js';

const useStyles = makeStyles((theme) => ({
  item: {
    position: 'relative',
    overflow: 'hidden',
    background: '#fff',
    borderBottom: '1px solid #E6E6E2',
    borderRadius: '0.4rem',
    marginBottom: '3rem',
    padding: '2rem',
    fontSize: '1.6rem',
    color: '#595959',
    boxShadow: theme.boxShadow,
    minHeight: '25.7rem',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto',
      marginBottom: '1rem'
    },
    [theme.breakpoints.down(330)]: {
      padding: '1rem'
    }
  },
  imageContainer: {
    width: '21.5rem',
    height: '21.5rem',
    flexShrink: 0,
    marginRight: '2.7rem',
    [theme.breakpoints.down('sm')]: {
      width: '12.8rem',
      height: '12.8rem'
    },
    [theme.breakpoints.down(330)]: {
      marginRight: '1rem'
    },
    '& img': {
      width: '100%'
    }
  },
  textContainer: {
    display: 'flex',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  itemDetails: {
    margin: '1rem 3rem 1rem 0',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      flexGrow: 0
    }
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.6rem'
    },
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
      '&:hover, &:focus': {
        textDecoration: 'underline'
      }
    }
  },
  price: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '0.6rem',
      flexGrow: 1
    },
    minWidth: 120,
    textAlign: 'right'
  },
  description: {
    overflow: 'hidden',
    marginTop: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  tagsContainer: {
    marginTop: '1.2rem',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    '& div': {
      marginRight: 10
    }
  },
  authorContainer: {
    marginTop: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  purchasedLabel: {
    color: theme.palette.styleguide.green,
    textTransform: 'uppercase'
  },
  readMore: {
    position: 'absolute',
    bottom: '2rem',
    right: '2rem',
    display: 'block',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      marginTop: '0.6rem',
      bottom: 0,
      right: 0
    },
    [theme.breakpoints.down(330)]: {
      '& button': {
        fontSize: '1.4rem',
        padding: '0.4rem 0.8rem'
      }
    }
  },
  subscription: {
    position: 'absolute',
    left: 30,
    top: 30
  }
}));

const ListItem = ({ product, hidePrice }) => {
  const classes = useStyles();
  
  const theme = useTheme();
  const hideDescription = useMediaQuery(theme.breakpoints.down('sm'));

  const isSubscription = product.productType === 'Subscription' || product.type === 'Subscription';
  let productUrl;
  if (isSubscription) {
    productUrl = buildInternalSubscriptionUrl(product);
  }
  else {
    productUrl = buildInternalProductUrl(product);
  }

  return (
    <div className={classes.item}>
      {
        isSubscription && (
          <Box className={classes.subscription}>
            <SubscriptionChip subscribed={product.subscribed} />
          </Box>
        )
      }
        
      <div className={classes.imageContainer}>
        <Link
          to={productUrl}
        >
          <img alt={product.title} src={product.images.cover} />
        </Link>
      </div>

      <div className={classes.textContainer}>
        <div className={classes.itemDetails}>
          <Typography variant='h2' className={classes.title}>
            <Link
              to={productUrl}
              className={classes.title}
            >
              {product.title}
            </Link>
          </Typography>
          <div className={classes.description}>
            <Typography variant='body2'>
              {/*
                'key' forces <Truncate> to redraw if the breakpoint changes
                otherwise it has a bug where the description won't reappear
                if the window is shrunk and enlarged again
              */}
              <Truncate
                key={hideDescription}
                line={2}
                element='span'
                text={product.description}
              />
            </Typography>
          </div>
          {
            product.tagsCache && (
              <div className={classes.tagsContainer}>
                <Tags tags={getGroupTagsFromTagsCache(product.tagsCache)} /> 
              </div>
            )
          }
          {
            product.author && (
              <div className={classes.authorContainer}>
                <AuthorLink author={product.author} />
              </div>
            )
          }
        </div>
        {
          !hidePrice && (
            <div className={classes.price}>
              <Price product={product} />
            </div>
          )
        } 
        <div>
          <Link
            to={productUrl}
            className={classes.readMore}
          >
            <StoreButton label='read-more' />
          </Link>
        </div>
      </div>
    </div>
  );
};

ListItem.propTypes = {
  product: PropTypes.object.isRequired,
  hidePrice: PropTypes.bool
};

export default ListItem;
