import { getIndex } from './global.js';
import { formatURLTitle } from './http.js';
import { convertGraphQLLanguageName } from './locale.js';
import {
  whoIsDeclarer,
  whoIsDummy,
  getContract
} from './auction.js';

const getHandPlayStatus = (hand, purchased) => {
  let status = 'locked';
  if (hand.freeToPlay) {
    status = 'play';
  }
  if (purchased) {
    status = 'play';
    if (hand.userHands && hand.userHands.length > 0) {
      if (hand.userHands[0].status === 'PartPlayed') {
        status = 'resume';
      } else {
        status = 'play-again';
      }
    }
  }
  return status;
};

const getNextHand = (handId, hands) => {
  const indexOfCurrentHand = getIndex(handId, hands);
  return hands[indexOfCurrentHand + 1];
};

const formatPathToHandPlay = (collectionId, set, hand) => {
  let pathToHandPlay = `/${convertGraphQLLanguageName(set.language)}`;
  if (collectionId || set.collection) {
    pathToHandPlay += `/collections/${collectionId}`;
  }
  pathToHandPlay += `/sets/${set.id}/${formatURLTitle(set.title)}/hands/${hand.id}/${formatURLTitle(hand.title)}`;
  return pathToHandPlay;
};

const formatPathToArticleHandPlay = (article, hand) => {
  const pathToHandPlay = `/articles/${article.publicId}/${formatURLTitle(article.title)}/hands/${hand.id}/${formatURLTitle(hand.title)}`;
  return pathToHandPlay;
};

// ====== admin hand helpers ======

const formatPathToAdminHand = ({ hand, parent, subscription }) => {
  let baseUrl;
  if (subscription) {
    baseUrl = `/admin/subscriptions/${subscription.id}/${formatURLTitle(subscription.title)}/articles/${parent.id}/${formatURLTitle(parent.title)}/hands/`;
  } else {
    baseUrl = `/admin/products/set/${parent.id}/${formatURLTitle(parent.title)}/hands/`;
    
  }
  if (hand) {
    return `${baseUrl}${hand.id}/${formatURLTitle(hand.title)}`;
  } else {
    return `${baseUrl}add`;
  }
};

const getPathBackToParentFromHand = ({ parent, subscription }) => {
  if (!subscription) {
    // its a set
    return `/admin/products/set/${parent.id}/${parent.title}`;
  } else {
    // its an article
    return `/admin/subscriptions/${subscription.id}/${subscription.title}/articles/${parent.id}/${parent.title}`;    
  }
};

const getHandProp = (handJson, prop, defaultValue) => {
  if (handJson) {
    if (prop === 'declarer' &&
      handJson.auction &&
      handJson.auction.length > 0 &&
      handJson.dealer
    ) {
      const declarer = whoIsDeclarer(handJson.auction, handJson.dealer);
      return declarer;
    } else if (prop === 'dummy' &&
      handJson.auction &&
      handJson.auction.length > 0 &&
      handJson.dealer
    ) {
      const declarer = whoIsDeclarer(handJson.auction, handJson.dealer);
      if (declarer) {
        return whoIsDummy(declarer);
      }
      return undefined;
    } else if (prop === 'contract' &&
      handJson.auction &&
      handJson.auction.length > 0 &&
      handJson.dealer
    ) {
      const contract = getContract(handJson.auction, handJson.dealer);
      return contract;
    }
    if (handJson[prop]) {
      return handJson[prop];
    }
  }
  return defaultValue;
};

const getHand = (handJson) => {
  return {
    deal: getHandProp(handJson, 'deal', []),
    dealer: getHandProp(handJson, 'dealer', 'N'),
    declarer: getHandProp(handJson, 'declarer', 'N'),
    dummy: getHandProp(handJson, 'dummy', ''),
    vulnerable: getHandProp(handJson, 'vulnerable', 'None'),
    viewpoint: getHandProp(handJson, 'viewpoint', 'S'),
    auctionOnly: getHandProp(handJson, 'auctionOnly', false),
    auctionIntro: getHandProp(handJson, 'auctionIntro', ''),
    auction: getHandProp(handJson, 'auction', []),
    contract: getHandProp(handJson, 'contract', {}),
    playOnly: getHandProp(handJson, 'playOnly', false),
    play: getHandProp(handJson, 'play', []),
    playIntro: getHandProp(handJson, 'playIntro', ''),
    tricks: getHandProp(handJson, 'tricks', {}),
    summary: getHandProp(handJson, 'summary', '')
  };
};

const getStepProgress = (handJSON) => {
  if (handJSON) {
    if (handJSON.summary &&
        handJSON.summary !== '') {
      return 3;
    }
    if (
      (handJSON.play &&
      handJSON.play.length > 0) || handJSON.auctionOnly
    ) {
      return 2;
    }
    if (
      handJSON.contract &&
      handJSON.contract.level &&
      handJSON.contract.denomination
    ) {
      return 1;
    }
    if (handJSON.deal &&
      handJSON.deal.length === 52
    ) {
      return 0;
    }
  }
  return -1;
};

// ====== END admin hand helpers ======

export {
  getHandPlayStatus,
  getNextHand,
  formatPathToHandPlay,
  formatPathToArticleHandPlay,
  getHand,
  getStepProgress,
  formatPathToAdminHand,
  getPathBackToParentFromHand
};
