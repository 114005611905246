import React from 'react';
import { makeStyles } from '@mui/styles';
import FeaturesList from '../../Components/Sales/FeaturesList.js';
import { useTranslation } from 'react-i18next';
import StoreSectionContainer from '../../Components/Store/StoreSectionContainer.js';

const useStyles = makeStyles((theme) => ({
  divider: {
    [theme.breakpoints.down('md')]: {
      borderBottom: '1px solid #A09896',
      paddingBottom: '4rem'
    }
  }
}));

const WhySubscribe = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <StoreSectionContainer
      heading={t('why-subscribe')}
      extraWhitespace
    >
      <div className={classes.divider}>
        <FeaturesList
          features={[
            {
              heading: t('interactive-reinforcement'),
              body: t('you-can-apply-and-reinforce'),
              icon: 'subscriptions-stack'
            },
            {
              heading: t('guided-learning-experience'),
              body: t('our-authors-create-immersive-environment'),
              icon: 'subscriptions-cards-1'
            },
            {
              heading: t('avoiding-bad-habits'),
              body: t('youre-safeguarded-against-bad-habits'),
              icon: 'subscriptions-red-circle'
            }
          ]}
        />
      </div>
      <FeaturesList
        features={[
          {
            heading: t('engaging-and-fun'),
            body: t('our-engaging-and-personable-teachers'),
            icon: 'subscriptions-thumbs-up'
          },
          {
            heading: t('manageable-mini-lessons'),
            body: t('convenience-makes-learning-effortless'),
            icon: 'subscriptions-screen'
          },
          {
            heading: t('gradual-skill-building'),
            body: t('approach-of-learning-small'),
            icon: 'subscriptions-cards-2'
          }
        ]}
      />
    </StoreSectionContainer>
  );
};

export default WhySubscribe;
