import React from 'react';
import { makeStyles } from '@mui/styles';
import HighlightSection from './HighlightSection.js';
import SuitPattern from '../SuitPattern.js';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: '74rem',
    overflow: 'hidden',
    backgroundColor: '#050503',
    // makes the red BG on the text container extend
    // to the left edge of the viewport
    '&:before': {
      content: '" "',
      position: 'absolute',
      right: 'calc(50% + 59rem)',
      left: 0,
      top: 0,
      bottom: 0,
      backgroundColor: '#e07d67'
    },
    [theme.breakpoints.down('md')]: {
      height: '56rem',
      '&:before': {
        content: 'none'
      }
    }
  },
  contentContainer: {
    maxWidth: '118rem',
    margin: '0 auto',
    zIndex: 1,
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  image: {
    backgroundImage: 'url(/img/components/sales/man1.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    height: '100%',
    position: 'absolute',
    width: 1000,
    left: '50%',
    right: 0,
    // this allows us to fade out the right-hand side of the image
    // so there's no clean edge
    maskImage: 'linear-gradient(to left, transparent 0%, rgba(0, 0, 0, 1) 2%)',
    [theme.breakpoints.down('xl')]: {
      left: '40%',
      maskImage: 'none'
    },
    [theme.breakpoints.down('lg')]: {
      left: '30%'
    },
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      left: 0,
      right: 0,
      width: '100%',
      height: '50%'
    }
  },
  textContainer: {
    position: 'absolute',
    left: 0,
    right: '50%',
    height: '100%',
    display: 'flex',
    padding: '5rem 5rem 5rem 1rem',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#e07d67',
    overflow: 'hidden',
    // slight shadow fade at the right edge of the copy section
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: 15,
      right: 0,
      backgroundImage: 'linear-gradient(to left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0))'
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '50%',
      left: 0,
      position: 'relative',
      padding: '4rem 2rem',
      textAlign: 'center',
      '&:after': {
        content: 'none'
      }
    }
  },
  suitPattern: {
    left: 360,
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

const SittingManCta = (props) => {
  const { heading, body, cta, onCtaClick } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.image} />
        <div className={classes.textContainer}>
          <HighlightSection
            heading={heading}
            body={body}
            cta={cta}
            onCtaClick={onCtaClick}
          />
          <SuitPattern
            opacity={0.07}
            className={classes.suitPattern}
          />
        </div>
      </div>
    </div>
  );
};

export default SittingManCta;
