import React from 'react';
import { adopt } from 'react-adopt';
import { useParams } from 'react-router-dom';
import { Mutation, ApolloConsumer } from '@apollo/react-components';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { mutations } from '../../../graphql';
import ProductForm from './ProductForm.js';
import Hands from './Hands/Hands.js';
import CollectionContainer from './Collection/CollectionContainer.js';
import { BBSnackbarConsumer } from '../../../Components/BBSnackbar/BBSnackbarContext.js';
import ProductByIdAdmin from '../../../Components/Queries/ProductByIdAdmin.js';
import EnumValues from '../../../Components/Queries/EnumValues.js';
import Tags from '../../../Components/Queries/Tags.js';
import Prices from '../../../Components/Queries/Prices.js';
import Authors from '../../../Components/Queries/Authors.js';

const ProductDetails = () => {
  let { id, type } = useParams();
  id = parseInt(id);
  const Composed = adopt({
    one: ({ render }) => <ProductByIdAdmin id={id}>{render}</ProductByIdAdmin>,
    two: ({ render }) => <Authors>{render}</Authors>,
    three: ({ render }) => <Tags>{render}</Tags>,
    four: ({ render }) => <EnumValues name='LanguageEnum'>{render}</EnumValues>,
    five: ({ render }) => <Prices>{render}</Prices>
  });
  return (
    <Composed>
      {({ one, two, three, four, five }) => {
        if (one) {
          return (
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='flex-start'
              spacing={2}
            >
              <Grid item xs={6}>
                <Paper>
                  <Mutation
                    mutation={mutations.UpsertProduct}
                  >
                    {upsertProduct => (
                      <Mutation
                        mutation={mutations.DeleteProduct}
                      >
                        {deleteProduct => (
                          <Mutation
                            mutation={mutations.DuplicateProduct}
                          >
                            {duplicateProduct => (
                              <BBSnackbarConsumer>
                                {({ openSnackbar }) => (
                                  <ProductForm
                                    upsertProduct={upsertProduct}
                                    deleteProduct={deleteProduct}
                                    duplicateProduct={duplicateProduct}
                                    product={one ? one.productByIdAdmin : null}
                                    authors={two}
                                    tags={three}
                                    languageEnum={four}
                                    prices={five}
                                    openSnackbarYouGibbon={openSnackbar}
                                    showCheckboxes
                                    type={type}
                                  />
                                )}
                              </BBSnackbarConsumer>
                            )}
                          </Mutation>
                        )}
                      </Mutation>
                    )}
                  </Mutation>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                {
                  one && one.productByIdAdmin
                    ? (
                      <ApolloConsumer>
                        {client => (
                          <>
                            {
                              type.toLowerCase() === 'set'
                                ? <Hands
                                    parent={one.productByIdAdmin}
                                    parentType='product'
                                  />
                                : <CollectionContainer
                                    collectionId={id}
                                    apolloClient={client}
                                  />
                            }
                          </>
                        )}
                      </ApolloConsumer>
                      )
                    : null
                }
              </Grid>
            </Grid>
          );
        } else {
          return null;
        }
      }}
    </Composed>
  );
};

export default ProductDetails;
