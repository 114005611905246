import React, { useState, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { mutations } from '../graphql';
import { 
  buildInternalSubscriptionUrl,
  buildInternalArticleUrl
} from '../helpers/subscription.js';
import { buildInternalProductUrl } from '../helpers/product.js';

const HandContext = createContext();

const HandProvider = ({ children }) => {
  const navigate = useNavigate();
  const [handProviderState, setHandProviderState] = useState(null);
  // state
  // {
  //   hand,
  //   parent,
  //   grandParent,
  //   currentUser,
  //   nextHandUrl,
  //   isPreview,
  //   linkId,
  //   isEmbed,
  //   isOnSale
  // }

  // what do we need to know

  // current hand
  // current product
  // next hand
  // is it owned
  // 
  
  // mutations
  const [upsertUserHand] = useMutation(mutations.UpsertUserHand, {
    ignoreResults: true
  });

  // event handlers
  const handleStepTaken = (type, step) => {
    const { currentUser, hand } = handProviderState;
    if (currentUser) {
      upsertUserHand({
        variables: {
          handId: hand.id,
          step: `${type}_${step}`,
          status: 'PartPlayed'
        }
      });
    }
  };

  const handleHandComplete = async () => {
    const { currentUser, hand } = handProviderState;
    if (currentUser) {
      await upsertUserHand({
        variables: {
          handId: hand.id,
          step: 'auction_0',
          status: 'Played'
        }
      });
    }
  };

  // call this to get back to the page where you can view the hands that this 
  // hand is a part of e.g. article page or set page
  const handleReturnToParent = () => {
    const { purchaseType, parent, grandParent, onHandleReturnToParent } = handProviderState;
    if (onHandleReturnToParent) {
      onHandleReturnToParent();
    } else {
      let url;
      if (purchaseType === 'product') {
        url = buildInternalProductUrl(grandParent || parent);
      } else {
        url = buildInternalArticleUrl(parent, grandParent);
      }
      navigate(url);
    }
  };

  // call this to get back to the page where you can buy the 'thing' that this 
  // hand belongs to. If this hand is part of a subscription, go back to the 
  // subscription page... if it is part of a collection go back to collection page
  const handleReturnToBuy = () => {
    const { purchaseType, parent, grandParent } = handProviderState;
    // is the grandparent a product or a subscription?
    // only products have a type
    let url;
    if (purchaseType === 'product') {
      if (grandParent) {
        url = buildInternalProductUrl(grandParent);
      } else {
        url = buildInternalProductUrl(parent);
      }
    } else {
      url = buildInternalSubscriptionUrl(grandParent);
    }
    navigate(url);
  };

  const handleBrowseStore = () => {
    navigate('/products');
  };

  const handleRestartHand = async () => {
    await handleHandComplete();
    window.location.reload();
  };

  const handlePlayNext = () => {
    window.location = handProviderState.nextHandUrl;
  };

  const getIsOnSale = () => {
    const { purchaseType, parent, grandParent } = handProviderState;
    if (purchaseType === 'product') {
      if (grandParent) {
        return grandParent.onSale;
      } else {
        return parent.onSale;
      }
    } else {
      return grandParent.active;
    }
  };

  const getAuthor = () => {
    let author;
    const { parent, grandParent } = handProviderState;
    if (parent.author) {
      author = parent.author;
    } else if (grandParent.author) {
      author = grandParent.author;
    }
    return author;
  };

  // Functions to move into context:

  // Return to product details
  // Browse products
  // Add to cart
  // Remove from cart
  // Replay hand (is restart hadn’t they same thing??)
  // Play next hand

  const init = ({ hand, parent, grandParent, currentUser, nextHandUrl, isPreview, linkId, isEmbed, onHandleReturnToParent }) => {
    // console.log('init', { hand, parent, grandParent, currentUser, nextHandUrl, isPreview, linkId, isEmbed });
    // only set state if the state is null or the hand we are trying to view is different
    // from the hand we have in state
    if (!handProviderState || hand.id !== handProviderState.hand.id) {
      setHandProviderState({
        hand,
        parent,
        grandParent,
        currentUser,
        nextHandUrl,
        isPreview, // previewed from admin
        linkId,
        isEmbed,
        onHandleReturnToParent,
        purchaseType: parent.author ? 'product' : 'subscription'
      });
    }
  };

  return (
    <HandContext.Provider value={
      { 
        init,
        hand: handProviderState && handProviderState.hand,
        handJSON: handProviderState && handProviderState.hand && handProviderState.hand.handJSON,
        parent: handProviderState && handProviderState.parent,
        grandParent: handProviderState && handProviderState.grandParent,
        nextHandUrl: handProviderState && handProviderState.nextHandUrl,
        isPreview: handProviderState && handProviderState.isPreview,
        linkId: handProviderState && handProviderState.linkId,
        isEmbed: handProviderState && handProviderState.isEmbed,
        purchaseType: handProviderState && handProviderState.purchaseType,
        handleStepTaken,
        handleHandComplete,
        handleReturnToParent,
        handleReturnToBuy,
        handleBrowseStore,
        handleRestartHand,
        handlePlayNext,
        getIsOnSale,
        getAuthor
      }
    }>
      {children}
    </HandContext.Provider>
  );
};

export { HandContext, HandProvider };

