import React from 'react';

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  thead: {
    '@media all and (max-width: 600px)': {
      border: 'none',
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      width: 1
    }
  },
  tr: {
    '@media all and (max-width: 600px)': {
      borderBottom: '3px solid #ddd',
      display: 'block',
      '& td': {
        borderBottom: '1px solid #ddd',
        display: 'block',
        textAlign: 'right',
        width: '100%'
      },
      '& > td:nth-child(4)': {
        borderBottom: '0px solid #ddd',
        display: 'block'
      },
      '& td::before': {
        content: 'attr(datalabel)',
        float: 'left'
      }
    }
  }
}));

export default function StoreTable ({ tableData }) {
  const { columns, rows } = tableData;

  const classes = useStyles();
  return (
    <Table align='left' sx={{ float: 'none' }}>
      <TableHead className={classes.thead}>
        <TableRow>
          {
            columns.map(column => (
              <TableCell>{column}</TableCell>
            ))
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {
          rows.map(row => (
            row && (
              <TableRow className={classes.tr}>
                {
                  columns.map((_, index) => (
                    <TableCell>
                      {row[index]}
                    </TableCell>
                  ))
                }
              </TableRow>
            )
          ))
        }
      </TableBody>
    </Table>
  );
}
