import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import GridView from '../GridView.js';
import StoreSectionContainer from '../StoreSectionContainer.js';

const ProductSection = ({ products, title, subtitle, viewAllPath, extraWhitespace }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(viewAllPath);
  };

  return (
    <StoreSectionContainer
      heading={title}
      subheading={subtitle}
      ctaButtonLabel='view-all'
      onCtaButtonClick={viewAllPath && handleClick}
      extraWhitespace={extraWhitespace}
    >
      <GridView
        nowrap
        products={products}
      />
    </StoreSectionContainer>
  );
};

ProductSection.propTypes = {
  products: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  viewAllPath: PropTypes.string,
  extraWhitespace: PropTypes.bool
};

export default ProductSection;
