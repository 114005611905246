import React from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Typography
} from '@mui/material';
import {
  Link
} from 'react-router-dom';
import TickIcon from '@mui/icons-material/CheckCircle';
import LockIcon from '@mui/icons-material/Lock';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { makeStyles } from '@mui/styles';
import StoreButton from '../../../../../Components/Forms/Store/StoreButton';
import {
  formatPathToHandPlay,
  getHandPlayStatus
} from '../../../../../helpers/hand.js';
import {
  buildProductLinkForHand
} from '../../../../../helpers/product.js';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 0
  },
  handsContainer: {
    border: '1px solid #BFBFBF',
    backgroundColor: theme.palette.background.default,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 2rem',
    minHeight: 66
  },
  unlockedHandsContainer: {
    backgroundColor: '#fff'
  },
  unlockedStepLabel: {
    color: theme.palette.styleguide.teal,
    padding: '4px 4px 4px 0'
  },
  lockedStepLabel: {
    color: '#BFBFBF',
    padding: '4px 4px 4px 0'
  }
}));

const SectionHands = ({ productId, set, linkId, purchased }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <Stepper orientation='vertical' connector={null}>
          <>
            {
              set.hands && set.hands.map((hand, index) => {
                const status = getHandPlayStatus(hand, purchased);
                let pathToHand;
                if (linkId) {
                  pathToHand = buildProductLinkForHand(set.language, linkId, hand, set.id, set.title, true);
                } else {
                  pathToHand = formatPathToHandPlay(productId, set, hand);
                }
                if (status === 'locked') {
                  return (
                    <Step key={index}>
                      <StepLabel
                        StepIconComponent={RadioButtonUncheckedIcon}
                        className={classes.lockedStepLabel}
                      >
                        <div className={classes.handsContainer}>
                          <Typography variant='h3'>
                            {hand.title}
                          </Typography>
                          <LockIcon sx={{ color: '#BFBFBF' }} />
                        </div>
                      </StepLabel>
                    </Step>
                  );
                } else {
                  return (
                    <Step sx={{ margin: 0 }} key={index}>
                      <StepLabel
                        StepIconComponent={TickIcon}
                        className={classes.unlockedStepLabel}
                      >
                        <div className={clsx(classes.handsContainer, classes.unlockedHandsContainer)}>
                          <Typography variant='h3'>
                            {hand.title}
                          </Typography>
                          <Link to={pathToHand} style={{ textDecoration: 'none' }}>
                            <StoreButton label={status} />
                          </Link>
                        </div>
                      </StepLabel>
                    </Step>
                  );
                }
              })
            }
          </>
        </Stepper>
      </div>
    </>
  );
};

export default SectionHands;
