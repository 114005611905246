import React from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-text-truncate';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Avatar,
  Box
} from '@mui/material';
import Price from '../../../Components/Store/Price.js';
import StoreButton from '../../../Components/Forms/Store/StoreButton.js';
import SubscriptionChip from '../../../Components/Store/Subscriptions/SubscriptionChip.js';
import { buildInternalSubscriptionUrl } from '../../../helpers/subscription.js';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 0,
  position: 'relative',
  width: '100%',
  boxShadow: theme.boxShadow,
  height: '100%'
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  borderBottom: 2,
  borderColor: '#d9d7d7',
  width: '100%',
  height: '28.6rem',
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: '2rem',
  display: 'flex',
  flexDirection: 'column',
  height: '40.2rem',
  [theme.breakpoints.down('sm')]: {
    height: 'auto'
  }
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.styleguide.maroon,
  fontWeight: 600,
  fontSize: '2.4rem',
  marginBottom: '2rem',
  flexGrow: 1
}));

const TitleLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.styleguide.maroon
  }
}));

const BottomRow = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  marginTop: '2rem',
  height: '3.6rem',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'start',
    height: '7.5rem'
  }
}));

const SubscriptionItemLarge = ({ product }) => {
  const productUrl = buildInternalSubscriptionUrl(product);
  const navigate = useNavigate();

  return (
    <StyledCard>
      <Link to={productUrl} style={{ textDecoration: 'none', position: 'relative', display: 'block' }}>
        <StyledCardMedia
          alt={product.title}
          image={product.images.cover}
          title={product.title}
        />
        <Box sx={{ position: 'absolute', bottom: 20, right: 20 }}>
          <SubscriptionChip subscribed={product.subscribed} />
        </Box>
      </Link>
      <StyledCardContent>
        <Avatar
          sx={{ width: 78, height: 78, marginBottom: '2rem' }}
          alt={product.author.fullName}
          src={product.author.avatarPath}
        />
        <TitleLink to={productUrl}>
          <Title component='div'>
            <Truncate line={1} text={product.title} />
          </Title>
        </TitleLink>
        <Box sx={{ flexGrow: 1 }}>
          <Typography sx={{ fontSize: 20 }} component='div'>
            <Truncate line={4} text={product.description} />
          </Typography>
        </Box>
        <BottomRow>
          <StoreButton
            label='more-info'
            onClick={() => { navigate(productUrl) }}
          />
          <Price product={product} />
        </BottomRow>
      </StyledCardContent>
    </StyledCard>
  );
};

SubscriptionItemLarge.propTypes = {
  product: PropTypes.object.isRequired
};

export default SubscriptionItemLarge;
