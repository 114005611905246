import React from 'react';
import { makeStyles } from '@mui/styles';
import SuitPattern from '../../Components/SuitPattern.js';
import StoreButton from '../../Components/Forms/Store/StoreButton.js';
import HeroComponent from '../../Components/Sales/Hero.js';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#E3E4DF',
    paddingTop: '12rem',
    paddingBottom: '14rem',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '4rem'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8rem'
    }
  },
  suitPattern: {
    left: -200,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

const Hero = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleTryClick = () => {
    // const elem = document.querySelector('#hands');
    // elem && elem.scrollIntoView();
    navigate('/products/1037/bridgebee-free-sample-hands');
  };

  return (
    <div className={classes.container}>
      <SuitPattern className={classes.suitPattern} />
      <HeroComponent
        title={t('the-best-players-are-always-learning')}
        body={t('play-and-practice-interactive-bridge-hands')}
        imageSrc='img/components/sales/tablet-cup.jpg'
        button={
          <StoreButton
            onClick={handleTryClick}
            label='practice-a-free-hand'
          />
        }
      />
    </div>
  );
};

export default Hero;
