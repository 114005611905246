import { getTagsByGroup } from './tag.js';

export const tagGroupsSorted = [
  'Purchase type',
  'Difficulty',
  'System',
  'Type',
  'Author'
];

/*
  takes a list of tags and authors from graphql and puts them together in a simpler format for filtering

  from:

  [
    { "id": 1, "name": "2/1", "tagGroup": { "id": 1, "name": "System" } }
    { "id": 2, "name": "Acol", "tagGroup": { "id": 1, "name": "System" } }
    { "id": 3, "name": "Beginner", "tagGroup": { "id": 2, "name": "Difficulty" } }
  ]

  [
    { "id": 1, "fullName": "Jane Doe" }
  ]

  to:

  {
    "System": ["2/1", "Acol"],
    "Difficulty": ["Beginner"],
    "Type": [],
    "Authors": ["Jane Doe"]
  }

  if passed featured author ids, it'll move those to the top of the authors list
*/
export const groupAndSimplifyTags = (allTags, allAuthors, featuredAuthorIds, language) => {
  featuredAuthorIds = featuredAuthorIds || [];
  const allTagsByGroup = {};

  tagGroupsSorted.forEach(groupName => {
    if (groupName === 'Author') {
      const featuredAuthorNames = [];
      const otherAuthorNames = [];

      // show featured authors first
      // and the rest ordered alphabetically
      allAuthors.forEach(author => {
        if (featuredAuthorIds.includes(author.id)) {
          featuredAuthorNames.push(author.fullName);
        } else {
          otherAuthorNames.push(author.fullName);
        }
      });

      allTagsByGroup[groupName] = featuredAuthorNames.concat(otherAuthorNames);
    } else if (groupName === 'Purchase type') {
      allTagsByGroup[groupName] = ['OneOff', 'Subscription'];
    } else {
      // remove 'Any system' as we no longer use this as a filter option
      allTags = allTags.filter(tag => tag.name !== 'Any system')
      allTagsByGroup[groupName] = getTagsByGroup(allTags, groupName, language)
        .map(tag => tag.name);
    }
  });

  return allTagsByGroup;
};

export const getSelectedTagsFromSearchParams = (searchParams) => {
  const selectedGroups = {};

  tagGroupsSorted.forEach(groupName => {
    if (searchParams.has(groupName)) {
      selectedGroups[groupName] = searchParams.get(groupName).split(',');
    } else {
      selectedGroups[groupName] = [];
    }
  });

  return selectedGroups;
};

export const getSearchParamsFromSelectedTags = (selectedTagsByGroup) => {
  const searchParams = {};
  for (const groupName in selectedTagsByGroup) {
    if (selectedTagsByGroup[groupName].length) {
      searchParams[groupName] = selectedTagsByGroup[groupName].join(',');
    }
  }

  return searchParams;
};

export const groupPromotedProducts = (products) => {
  const groups = {
    subscriptions: [],
    latest: []
  };

  products.forEach((product) => {
    if (product.productType === 'Subscription') {
      groups.subscriptions.push(product);  
    } else {
      groups.latest.push(product);
      
      product.tagsCache && product.tagsCache.forEach(tag => {
        if (tag) {
          if (!groups[tag.name]) {
            groups[tag.name] = [];
          }
  
          groups[tag.name].push(product);
        }
      });
    }
  });

  // sort by descending ID (so newest first)
  groups.latest.sort((a, b) => {
    return b.id - a.id;
  });

  for (const groupName in groups) {
    if (groups[groupName].length < 1) {
      delete groups[groupName];
    } else {
      groups[groupName] = groups[groupName].slice(0, 4);
    }
  }

  return groups;
};
