import React from 'react';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

export default function ArticleCalendar ({ availableArticles, selectedArticleId, onArticleClick }) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateCalendar />
    </LocalizationProvider>
  );
}
