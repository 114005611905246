import React from 'react';
import PropTypes from 'prop-types';
import { userQueries } from '../../graphql/queries/index.js';
import BBQuery from './BBQuery.js';

// uses render props pattern - https://reactjs.org/docs/render-props.html
// in this case prop is 'children' not 'render' but its the same concept

class UserPurchases extends React.Component {
  render () {
    const { userId, children } = this.props;
    return (
      <BBQuery
        query={userQueries.UserPurchases}
        variables={{
          id: userId
        }}
        showLoader
        fetchPolicy='no-cache'
      >
        {data => {
          return children(data.userPurchases);
        }}
      </BBQuery>
    );
  }
}

UserPurchases.propTypes = {
  children: PropTypes.func.isRequired,
  userId: PropTypes.number
};

export default UserPurchases;
