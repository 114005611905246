import React from 'react';
import { styled } from '@mui/styles';
import {
  Chip,
  Box
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';

const ContainerBox = styled(Box)({
  backgroundColor: '#ffffff',
  padding: 2,
  borderRadius: 18,
  display: 'inline-block'
})

const Subscribed = () => (
  <>
    <Chip
      label='Subscribed'
      color='blue'
      icon={<VerifiedIcon />}
    />
  </>
);

const NotSubscribed = () => (
  <>
    <Chip
      label='Subscription'
      color='blue'
      variant='outlined'
    />
  </>
);

export default function SubscriptionChip ({ subscribed }) {
  return (
    <ContainerBox>
      {
        subscribed 
          ? <Subscribed />
          : <NotSubscribed />
      }
    </ContainerBox>
  );
}
