import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { mutations } from '../../../graphql';
import { 
  Menu,
  MenuItem,
  Divider
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Spellcheck as ApproveIcon,
  Backspace as RejectIcon,
  Description as MakeDraftIcon,
  CheckCircle as FreeIcon,
  Cancel as NotFreeIcon,
  Launch as PreviewIcon,
  Publish as PublishIcon,
  Flaky as SendForApprovalIcon
} from '@mui/icons-material';
import { UserContext } from '../../../Context';
import { BBSnackbarContext } from '../../../Components/BBSnackbar/BBSnackbarContext';
import SavingBackdrop from '../../../Components/Forms/SavingBackdrop';
import styled from '@emotion/styled';

// admin 
// -----
// make draught (if approved or published)
// delete (if draft)
// approve/reject (if pending)

// publisher
// ---------
// make draught (if pending)
// send for aproval (if draft)
// delete (if draft)

// editor
// ------
// same as admin

// all
// ---
// preview

const StyledMenuItem = styled(MenuItem)({
  '& svg': {
    marginRight: 10
  }
});

export default function ArticleMenu ({ anchorEl, article, subscriptionId, open, onClose, refetchSubscriptions }) {
  const { user } = useContext(UserContext);
  const { openSnackbar } = useContext(BBSnackbarContext);

  const [backdropOpen, setBackdropOpen] = useState(false);

  const [upsertArticle] = useMutation(mutations.UpsertArticle);
  const [deleteArticle] = useMutation(mutations.DeleteArticle);
  const [publishArticle] = useMutation(mutations.PublishArticle);
  const [previewArticle] = useMutation(mutations.PreviewArticle);

  const callMutation = async (mutation, args, successMessage) => {
    setBackdropOpen(true);
    args.subscriptionId = subscriptionId;
    try {
      await mutation({ variables: args });
      await refetchSubscriptions();
      openSnackbar(successMessage || 'Success!', 'success');
    } catch {
      openSnackbar('Update failed', 'error');
    }
    setBackdropOpen(false);
    onClose();
  };

  const handleDeleteClick = () => {
    callMutation(deleteArticle, {
      id: article.id
    }, 'Article deleted');
  };
  
  const handleApproveClick = () => {
    callMutation(upsertArticle, {
      ...article,
      status: 'Approved'
    }, 'Article approved');
  };

  const handleRejectClick = () => {
    callMutation(upsertArticle, {
      ...article,
      status: 'Draft'
    }, 'Article rejected');
  };

  const handleMakeDraftClick = () => {
    callMutation(upsertArticle, {
      ...article,
      status: 'Draft'
    }, 'Article made draft');
  };

  const handSendForApproval = () => {
    callMutation(upsertArticle, {
      ...article,
      status: 'Pending'
    }, 'Article sent for approval');
  };

  const handleToggleFreeClick = () => {
    callMutation(upsertArticle, {
      ...article,
      freeToRead: !article.freeToRead
    }, `Article made ${article.freeToRead && 'NOT ' }free`);
  };

  const handlePublishClick = () => {
    callMutation(publishArticle, {
      id: article.id
    }, 'Article published');
  };

  const handlePreviewClick = () => {
    callMutation(previewArticle, {
      id: article.id
    }, 'Preview of article sent');
  };

  const allActions = {
    draft: { icon: <MakeDraftIcon />, label: 'Make Draft', onClick: handleMakeDraftClick },
    sendForApproval: { icon: <SendForApprovalIcon />, label: 'Send For Approval', onClick: handSendForApproval },
    approve:  { icon: <ApproveIcon />, label: 'Approve', onClick: handleApproveClick },
    reject: { icon: <RejectIcon />, label: 'Reject', onClick: handleRejectClick },
    delete: { icon: <DeleteIcon />, label: 'Delete', onClick: handleDeleteClick },
    publish: { icon: <PublishIcon />, label: 'Publish Now', onClick: handlePublishClick },
    free: { icon: <FreeIcon />, label: 'Make Free', onClick: handleToggleFreeClick },
    notFree: { icon: <NotFreeIcon />, label: 'Remove Free', onClick: handleToggleFreeClick }
  };

  const getAdminActions = () => {
    switch (article.status) {
      case 'Draft':
        return [allActions.delete];
      case 'Pending':
        return [
          allActions.approve,
          allActions.reject
        ];
      case 'Approved':
        return [
          article.freeToRead ? allActions.notFree : allActions.free,
          allActions.draft,
          allActions.publish
        ];
      case 'Published':
        return [
          article.freeToRead ? allActions.notFree : allActions.free,
          allActions.draft
        ];
      default:
        throw Error('ArticleMenu article.status not recognised');
    }
  };

  const getPublisherActions = () => {
    switch (article.status) {
      case 'Draft':
        return [
          allActions.delete,
          allActions.sendForApproval
        ];
      case 'Pending':
        return [
          allActions.draft,
        ];
      case 'Approved':
      case 'Published':
        return [];
      default:
        throw Error('ArticleMenu article.status not recognised');
    }
  };

  if (!article) {
    return null;
  }

  let actions;
  switch (user.type) {
    case 'Admin':
      actions = getAdminActions();
      break;
    case 'Publisher':
      actions = getPublisherActions();
      break;
    case 'Editor':
      actions = getAdminActions();
      break;
    default:
      throw Error('ArticleMenu user.type not recognised');
  }
  
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          border: 'solid 1px #eee'
        }
      }}
    >
      {
        actions.map(action => (
          <StyledMenuItem onClick={action.onClick}>
            {action.icon}
            {action.label}
          </StyledMenuItem>
        ))
      }
      <Divider sx={{ my: 0.5 }} />
      <StyledMenuItem onClick={handlePreviewClick}>
        <PreviewIcon />
        Preview
      </StyledMenuItem>
      <SavingBackdrop open={backdropOpen} text='Saving...' />
    </Menu>
  )
}