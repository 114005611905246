import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Button, Backdrop, Slide } from '@mui/material';
import MessageTypeSelector from './MessageTypeSelector.js';
import { HandContext } from '../../../Context/HandContext.js';

// √ only when mobile (isMobile === true)
// √ only when sidebar/bottombar is closed (sidebarExpanded === false)
// √ only author comments (not system messages)
// √ add continue button
// √ show latest unread message (how do we mark messages as read)
// √ pause play whilst the modal is open
// √ need to support 2 consecutive messages (could try 1 for now)
// √ only show when 'show popup guidance' is on

const useStyles = makeStyles((theme) => ({
  backdrop: {
    // top: '-3rem',
    paddingTop: '3rem',
    zIndex: 1000,
    textAlign: 'center',
    // background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%)',
    // background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.25) 2%, rgba(0,0,0,0.25) 100%)',
    // background: 'transparent',
    userSelect: 'none',
    '&:active': {
      // opacity: 0.15
    }
  },
  backdropMini: {
    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 80%, rgba(0,0,0,0.35) 100%)',
    alignItems: 'flex-end',
    '&:active': {
      opacity: 1
    }
  },
  avatar: {
    position: 'relative',
    margin: '0 auto',
    width: '7rem',
    height: '7rem',
    background: '#fff',
    boxShadow: '0 0 2rem rgba(0,0,0,0.25)',
    border: '0.4rem solid black',
    borderColor: theme.palette.styleguide.yellow,
    borderRadius: '100%',
    overflow: 'hidden',
    lineHeight: 0,
    marginBottom: '-5rem',
    zIndex: 1,
    '& img': {
      width: '100%'
    }
  },
  container: {
    // width: '30rem',
    width: '28rem',
    // padding: '6rem 2rem 2rem 2rem',
    padding: '4rem 1rem 1rem 1rem',
    border: '0.4rem solid transparent',
    background: '#fff',
    boxShadow: '0 0 3rem rgba(0,0,0,0.5)',
    borderColor: theme.palette.styleguide.yellow,
    borderRadius: '2rem',
    marginBottom: '5rem'
  },
  containerMini: {
    padding: '0 1rem'
  },
  contentContainer: {
    maxHeight: '20rem',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    overflow: 'hidden',
    overflowY: 'auto',
    marginTop: '1rem'
  },
  contentContainerMini: {

  },
  button: {
    display: 'block',
    width: '100%',
    marginTop: '2rem',
    color: '#fff',
    background: theme.palette.styleguide.yellow,
    '&:disabled': {
      color: '#fff'
    },
    '&:hover': {
      background: '#FFC45D'
    }
  }
}));

const Transition = React.forwardRef(function Transition (props, ref) {
  return (
    <>
      <Slide direction='up' ref={ref} {...props} />
    </>
  );
});

export default function PortraitCommentModal ({ message, open, onContinueClick }) {
  const classes = useStyles();
  const mini = false;
  const [disabled, setDisabled] = useState(true);

  const { getAuthor } = useContext(HandContext);
  const author = getAuthor();
  
  useEffect(() => {
    if (open) {
      // we have to put a delay in here as 
      const delay = message.type === 'comment' ? 1000 : 0;
      setTimeout(() => {
        setDisabled(false);
      }, delay);
    }
  }, [open, message]);

  const handleContinueClick = () => {
    setDisabled(true);
    onContinueClick();
  };

  return (
    <Backdrop
      open={open}
      className={clsx(classes.backdrop, mini && classes.backdropMini)}
    >
      <Backdrop
        open={open}
        TransitionComponent={Transition}
        className={clsx(classes.backdrop, mini && classes.backdropMini)}
        onClick={mini ? handleContinueClick : undefined}
      >
        <div className={classes.backdropInner}>
          {
            !mini && (
              <div className={classes.avatar}>
                <img className={classes.img} src={author.avatarPath} alt={author.fullName} />
              </div>
            )
          }
          <div className={clsx(classes.container, mini && classes.containerMini)}>
            <div className={clsx(classes.contentContainer, mini && classes.contentContainerMini)}>
              <MessageTypeSelector message={message} />
            </div>
            {
              !mini && (
                <Button
                  onClick={handleContinueClick}
                  disabled={disabled}
                  variant='contained'
                  color='secondary'
                  className={classes.button}
                  size='large'
                >
                  Continue
                </Button>
              )
            }
          </div>
        </div>
      </Backdrop>
    </Backdrop>
  );
};

PortraitCommentModal.propTypes = {
  message: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onContinueClick: PropTypes.func.isRequired
};
