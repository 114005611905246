import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'block',
    height: 30,
    lineHeight: '30px',
    '& img': {
      height: 30
    }
  },
  button: {
    margin: theme.spacing(1),
    color: 'white !important'
  },
  navButton: {
    textDecoration: 'none',
    color: 'white',
    '&:hover': {
      textDecoration: 'underline'
    },
    margin: theme.spacing(3)
  },
  nav: {
    marginLeft: 100
  },
  grow: {
    flexGrow: 1
  },
  links: {
    marginTop: 20,
    height: 220,
    width: 180
  },
  link: {
    textDecoration: 'none',
    color: 'black'
  }
}));

const AdminNav = ({ currentUser }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [linksOpen, setLinksOpen] = useState(false);
  const [linksAnchor, setLinksAnchor] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuOpen(true);
    setMenuAnchor(event.currentTarget);
  };

  const handleLinksOpen = (event) => {
    setLinksOpen(true);
    setLinksAnchor(event.currentTarget);
  };

  const handleMenuClick = (action) => {
    setMenuOpen(false);
    switch (action) {
      case 'logout':
        handleLogoutClick();
        break;
      default:
        return navigate(`/${action}`);
    }
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleCloseLinks = () => {
    setLinksOpen(false);
  };

  const handleLogoutClick = () => {
    navigate('/logout');
  };

  let adminLinks = [
    { to: '/admin/products', title: 'Products' },
    { to: '/admin/subscriptions', title: 'Subscriptions' }
  ];
  if (currentUser.type === 'Admin') {
    adminLinks = [
      { to: '/admin/sales', title: 'Sales' },
      { to: '/admin/products', title: 'Products' },
      { to: '/admin/subscriptions', title: 'Subscriptions'},
      { to: '/admin/users', title: 'Users' },
      { to: '/admin/authors', title: 'Authors' },
      { to: '/admin/royalties', title: 'Royalties' },
      { to: '/admin/tags', title: 'Tags' },
      { to: '/admin/vouchers', title: 'Vouchers' }
    ];
  }

  const externalLinks = [
    { to: 'https://dashboard.stripe.com/test/dashboard/', title: 'Stripe' },
    { to: 'https://app.sendgrid.com/', title: 'Sendgrid' },
    { to: 'https://sentry.io/organizations/bridge-bee/projects/', title: 'Sentry' },
    { to: 'https://engine.apollographql.com/', title: 'Apollo engine' }
  ];

  return (
    <div>
      <AppBar position='static'>
        <Toolbar>
          <Typography variant='h5' color='inherit'>
            <Link to='/admin' className={classes.logo}>
              <img src='/img/admin/header/bridgebee_logo.svg' alt='bridgebee logo' />
            </Link>
          </Typography>
          <div className={classes.nav}>
            {
              adminLinks.map((link, index) => {
                return (
                  <NavLink
                    key={index}
                    className={classes.navButton}
                    to={link.to}
                  >
                    {link.title}
                  </NavLink>
                );
              })
            }
            {
              currentUser.type === 'Admin'
                ? <>
                  <Button
                    variant='outlined'
                    className={classes.button}
                    onClick={handleLinksOpen}
                  >
                    External links
                    <ArrowDropDownIcon className={classes.rightIcon} />
                  </Button>
                  <Menu
                    PopoverClasses={{
                      paper: classes.links
                    }}
                    id='simple-menu'
                    keepMounted
                    open={linksOpen}
                    onClose={handleCloseLinks}
                    anchorEl={linksAnchor}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                  >
                    {
                      externalLinks.map((link, index) => {
                        return (
                          <a
                            key={index}
                            target='_blank'
                            href={link.to}
                            rel='noopener noreferrer'
                            className={classes.link}
                          >
                            <MenuItem>
                              {link.title}
                            </MenuItem>
                          </a>
                        );
                      })
                    }
                  </Menu>
                </>
                : null
            }
          </div>
          <div className={classes.grow} />
          <div>
            <IconButton
              className={classes.button}
              edge='end'
              aria-label='Account settings'
              aria-haspopup='true'
              onClick={handleMenuOpen}
              color='inherit'
              size='large'
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id='account-menu'
              anchorEl={menuAnchor}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={menuOpen}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={() => { handleMenuClick('products'); }}>Customer area</MenuItem>
              <MenuItem onClick={() => { handleMenuClick('logout'); }}>Log out</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

AdminNav.propTypes = {
  currentUser: PropTypes.object.isRequired
};

export default AdminNav;
