import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box, 
  Card,
  CardMedia
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AuthorLink from '../../../../../Components/Store/AuthorLink.js';
import Tags from '../../../../../Components/Store/Tags.js';
import Price from '../../../../../Components/Store/Price.js';
import StoreButton from '../../../../../Components/Forms/Store/StoreButton.js';
import { getGroupTagsFromTagsCache } from '../../../../../helpers/tag.js';
import { formatURLTitle } from '../../../../../helpers/http.js';

const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: 320,
  position: 'relative',
  boxShadow: theme.boxShadow,
  overflow: 'visible',
  borderRadius: '1.5rem',
  transition: '0.4s',

  '&:hover': {
    '& .Shadow1': {
      bottom: '-1.5rem',
    },
    '& .Shadow2': {
      bottom: '-2.5rem',
    },
  }
}));

const StyledCardMedia = styled(CardMedia)({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.08)',
  backgroundPosition: 'center',
});

const BoxMain = styled(Box)(() => ({
  overflow: 'hidden',
  borderTopLeftRadius: '1.5rem',
  borderTopRightRadius: '1.5rem',
  zIndex: 1
}));

const TagsContent = styled('div')(() => ({
  position: 'absolute',
  top: 10,
  right: 10,
  width: '100%',
  zIndex: 1,
  padding: '1.5rem 1.5rem 1rem',
  textAlign: 'right'
}));

const PriceContent = styled('div')(() => ({
  position: 'absolute',
  bottom: 10,
  right: 10,
  width: '100%',
  zIndex: 1,
  padding: '1.5rem 1.5rem 1rem',
  textAlign: 'right'
}));

const RowAuthor = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  minWidth: 0,
  padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  margin: 0,
  backgroundColor: theme.palette.mode === 'dark' ? 'grey.900' : '#fff',
  zIndex: 1,
  position: 'relative',
  borderBottomLeftRadius: '1.5rem',
  borderBottomRightRadius: '1.5rem',
}));

export default function SubscriptionBanner ({ subscription }) {
  return (
    <StyledCard>
      <BoxMain minHeight={200} position={'relative'}>
        <StyledCardMedia
          image={subscription.images.banner}
        />
        <TagsContent>
          <Tags tags={getGroupTagsFromTagsCache(subscription.tagsCache)} />
        </TagsContent>
        {
          subscription.plans ?
          (
            <PriceContent>
              <Price 
                variant='h1'
                price={`${subscription.plans[0].priceLocalFormatted} / ${subscription.plans[1].priceLocalFormatted}`}
              />
            </PriceContent>
          )
          : null
        }
      </BoxMain>
      <RowAuthor>
        <Box>
          <AuthorLink author={subscription.author} />
        </Box>
        <Box>
          <Link
            to={`${subscription.id}/${formatURLTitle(subscription.title)}`}
          >
            <StoreButton label='read-more' />
          </Link>
        </Box>
      </RowAuthor>
    </StyledCard>
  );
};
