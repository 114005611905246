import React from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-text-truncate';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box
} from '@mui/material';
import Tags from './Tags.js';
import Price from './Price.js';
import AuthorLink from './AuthorLink.js';
import SubscriptionChip from './Subscriptions/SubscriptionChip.js';
import { getGroupTagsFromTagsCache } from '../../helpers/tag.js';
import { buildInternalProductUrl } from '../../helpers/product.js';
import { buildInternalSubscriptionUrl } from '../../helpers/subscription.js';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    borderRadius: 0,
    position: 'relative',
    boxShadow: theme.boxShadow,
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none'
    }
  },
  cardNowrap: {
    width: '28rem',
    borderRadius: 0,
    position: 'relative',
    boxShadow: theme.boxShadow,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '20rem'
    }
  },
  image: {
    borderBottom: 2,
    borderColor: '#d9d7d7',
    width: '100%',
    height: 0,
    // CSS hack - padding % is always based on width
    // so this effectively makes the image square
    paddingBottom: '100%'
  },
  content: {
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 0 2rem 0'
    }
  },
  contentNowrap: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem'
  },
  title: {
    color: theme.palette.styleguide.maroon,
    fontWeight: 600,
    minHeight: '4.8rem',
    marginBottom: '3rem',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  titleLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.styleguide.maroon
    }
  },
  bottomRow: {
    marginTop: '1rem',
    height: '3.6rem'
  },
  author: {
    color: '#595959',
    fontSize: '1.2rem'
  },
  price: {
    color: theme.palette.styleguide.pink,
    fontWeight: 'bold'
  },
  tagsContainer: {
    minHeight: '6.8rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  subscription: {
    position: 'absolute',
    left: 20,
    top: 20
  }
}));

const TruncatedText = ({ title }) => (
  <Truncate
    line={2}
    text={title}
  />
);

const GridItem = ({ product, nowrap }) => {
  const classes = useStyles({ nowrap });
  const isSubscription = product.productType === 'Subscription';
  let productUrl;
  if (isSubscription) {
    productUrl = buildInternalSubscriptionUrl(product);
  }
  else {
    productUrl = buildInternalProductUrl(product);
  }
  
  return (
    <Card className={nowrap ? classes.cardNowrap : classes.card}>
      {
        isSubscription && (
          <Box className={classes.subscription}>
            <SubscriptionChip subscribed={product.subscribed} />
          </Box>
        )
      }   
      <Link to={productUrl} style={{ textDecoration: 'none' }}>
        <CardMedia
          alt={product.title}
          className={classes.image}
          image={product.images.cover}
          title={product.title}
        />
      </Link>
      <CardContent className={nowrap ? classes.contentNowrap : classes.content}>
        <Link to={productUrl} className={classes.titleLink}>
          <Typography className={classes.title} component='div'>
            <TruncatedText title={product.title} />
          </Typography>
        </Link>
        <Box className={classes.tagsContainer}>
          <Tags tags={getGroupTagsFromTagsCache(product.tagsCache)} />
        </Box>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          wrap='nowrap'
          className={classes.bottomRow}
        >
          <Grid item>
            {
              product.author &&
                <AuthorLink author={product.author} noAvatar />
            }
          </Grid>
          <Grid item>
            <Price product={product} />        
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

GridItem.propTypes = {
  product: PropTypes.object.isRequired,
  nowrap: PropTypes.bool
};

export default GridItem;
