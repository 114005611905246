import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import BiddingBox from '../../../../Components/Hands/BiddingBox/BiddingBox.js';
import AuctionTable from '../../../../Components/Hands/Auction.js';
import CardListAuction from '../../../../Components/Hands/Cards/CardListAuction.js';
import clsx from 'clsx';

const styles = {
  root: {
    position: 'absolute',
    left: 0, top: 0, right: 0, bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    maxWidth: '100%',
    height: '100%'
  },
  auction: {
    background: 'rgba(0,0,0,0.2)',
    border: '0.2em solid rgba(255,255,255,0.25)',
    borderRadius: '1em',
    padding: '2em',
    color: '#fff',
    height: '100%'
  },
  deal: {
    whiteSpace: 'nowrap',
    pointerEvents: 'none',
    userSelect: 'none'
  },
  dealMobile: {
    marginBottom: '-8em'
  }
};

class Auction extends React.Component {

  constructor (props) {
    super(props);
    const { auctionDelay } = props;
    setTimeout(() => {
      this.autoBid();
    }, auctionDelay);
  }

  autoBid = () => {
    const {
      onBidToPlay,
      onAuctionComplete,
      viewpoint,
      auction,
      paused,
      activeSeat,
      handAuction
    } = this.props;

    if (!paused) {
      if (auction.length === handAuction.length) {
        return onAuctionComplete();
      }

      // get the bid the author chose for this seat
      const correctBid = handAuction[auction.length];

      if (activeSeat === viewpoint) {
        // check for pre comment
        onBidToPlay(correctBid, auction.length);
      } else {
        // auto play the bid
        this.makeBid(correctBid, 'left');
      }
    }
  };

  makeBid = (bid) => {
    const { onBidPlayed, bidDelay } = this.props;

    const hasComment = onBidPlayed(bid);

    setTimeout(() => {
      this.autoBid();
    }, hasComment? bidDelay * 2 : bidDelay);
  };

  handleBidClick = (bid) => {
    const { auction, handAuction, onWrongChoice } = this.props;

    // is it successful
    const correctBid = handAuction[auction.length];
    if (bid.value === correctBid.value) {
      this.makeBid(correctBid, 'right');
    } else {
      onWrongChoice(correctBid, 'auction');
    }
  };

  componentDidUpdate (prevProps) {
    const { paused, canPlay } = this.props;
    // check to see if the auction has restarted after a pause
    if (paused !== prevProps.paused && paused === false) {
      // if its not our go (canPlay = false)
      // then continue with the auto bidding
      if (!canPlay) {
        this.autoBid();
      }
    }
  }

  render () {
    const {
      auction,
      classes,
      cards,
      dealer,
      viewpoint,
      isPortrait,
      componentScale,
      screenDimensions,
      aspectRatio,
      activeSeat,
      canPlay
    } = this.props;

    const isMobile = screenDimensions.width <= 540;
    let scale = componentScale / 1.5;
    if (isPortrait && !isMobile) {
      scale = componentScale / 2.1;
    }

    switch (aspectRatio) {
      case '3:4':
        // ipad
        scale = componentScale / 1.6;
        break;
      case '9:16':
        // smaller phone
        scale = componentScale / 1.5;
        break;
      case '9:19.5':
        // newer taller phone
        scale = componentScale / 1.5;
        break;
      default:
        break;
    }

    return (
      <div
        className={classes.root}
        style={{fontSize:`${scale}em`}}
      >
        <div className={classes.container}>
          <Grid
            container
            direction='column'
            style={{height:'100%'}}
            wrap='nowrap'
          >
            <Grid item xs>
              <Grid
                container
                alignItems='center'
                justifyContent='center'
                style={{height:'100%'}}
              >
                <Grid item>
                  <Grid
                    container
                    direction={isMobile ? 'column' : 'row'}
                    spacing={isMobile ? 2 : 1}
                    wrap='nowrap'
                  >
                    <Grid item>
                      <BiddingBox
                        dealer={dealer}
                        auction={auction}
                        onClick={this.handleBidClick}
                        clickable={activeSeat === viewpoint && canPlay}
                        showTurnHint={true}
                      />
                    </Grid>
                    <Grid item xs>
                      <div className={classes.auction}>
                        <AuctionTable
                          dealer={dealer}
                          auction={auction}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={clsx(classes.deal, isMobile && classes.dealMobile)}>
              <CardListAuction
                cards={cards}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

Auction.propTypes = {
  onWrongChoice: PropTypes.func.isRequired,
  onBidToPlay: PropTypes.func.isRequired,
  onBidPlayed: PropTypes.func.isRequired,
  onAuctionComplete: PropTypes.func.isRequired,
  cards: PropTypes.array.isRequired,
  dealer: PropTypes.string.isRequired,
  viewpoint: PropTypes.string.isRequired,
  auction: PropTypes.array.isRequired,
  activeSeat: PropTypes.string.isRequired,
  auctionDelay: PropTypes.number.isRequired,
  bidDelay: PropTypes.number.isRequired,
  paused: PropTypes.bool,
  handAuction: PropTypes.array.isRequired,
  canPlay: PropTypes.bool,
  isPortrait: PropTypes.bool,
  componentScale: PropTypes.number,
  screenDimensions: PropTypes.object,
  aspectRatio: PropTypes.string,
};

export default withStyles(styles)(Auction);
