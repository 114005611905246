import React from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const StripeWrapper = ({ children, stripeApiKey }) => {
  const stripe = loadStripe(stripeApiKey);

  return (
    <Elements stripe={stripe}>
      {children}
    </Elements>
  );
};

StripeWrapper.propTypes = {
  children: PropTypes.element,
  stripeApiKey: PropTypes.string.isRequired
};

export default StripeWrapper;
