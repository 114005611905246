import React from 'react';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function MoreMenuButton ({ onClick }) {
  return (
    <IconButton
      onClick={onClick}
    >
      <MoreVertIcon />
    </IconButton>
  );
}