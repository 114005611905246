import React from 'react';
import StoreButton from '../../../../Components/Forms/Store/StoreButton';
import StoreTable from '../../../../Components/Store/StoreTable';

const Actions = (props) => {
  const {
    userSubscriptionPlanId,
    userSubscriptionPlanType,
    onCancelSubscriptionClick,
    onChangeSubscriptionClick
  } = props;
  const handleCancelClick = () => {
    onCancelSubscriptionClick(userSubscriptionPlanId);
  };

  const handleChangeClick = () => {
    onChangeSubscriptionClick(userSubscriptionPlanId);
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ marginRight: 10 }}>
        <StoreButton onClick={handleCancelClick} label='Cancel'/>
      </div>
      {
        userSubscriptionPlanType === 'Monthly' && (
          <div>
            <StoreButton
              onClick={handleChangeClick}
              label={'Change to yearly (Save 10%)'}
            />
          </div>
        )
      }
    </div>
  );
}

export default function SubscriptionTable ({ userSubscriptionPlans, onCancelSubscriptionClick, onChangeSubscriptionClick }) {
  const rows = userSubscriptionPlans.map(usp => {
    return [
      usp.subscriptionPlan.subscription.title,
      usp.subscriptionPlan.type,
      usp.startDateTimeUniversal,
      <Actions
        userSubscriptionPlanId={usp.id}
        userSubscriptionPlanType={usp.subscriptionPlan.type}
        onCancelSubscriptionClick={onCancelSubscriptionClick}
        onChangeSubscriptionClick={onChangeSubscriptionClick}
      />
    ]
  });

  const tableData = {
    columns: [
      'Product',
      'Plan',
      'Start date',
      'Actions'
    ],
    rows
  };

  return (
    <StoreTable tableData={tableData} />
  );
}