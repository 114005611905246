import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import CardSuitGroup from './CardSuitGroup.js';
import {
  getCardsForSuit,
  getOrderOfSuitsForCardList
} from '../../../helpers/card.js';
import {
  useComponentScale,
  useCardVariant,
  useOrientation
} from '../../../Hooks';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  isSpread: {
    paddingRight: '7em'
  },
  isLeftOrRightStack: {
    width: 'auto',
    flexDirection: 'column',
    transform: 'none !important'
  },
  index0Portrait: {
    padding: 0,
    width: '100%'
  },
  index0PortraitFlipped: {
    marginTop: '-11em'
  },
  index0Stack: {
    padding: 0,
    maxWidth: '100%',
    height: '6rem'
  },
  index1: {
    transform: 'rotate(-90deg) translateX(calc(50% + 7em))',
    transformOrigin: '100% 100% 0'
  },
  index1Portrait: {
    height: '17%',
    paddingRight: '18rem'
  },
  index1PortraitFlipped: {
    marginRight: '-11em'
  },
  index2Portrait: {
    padding: 0,
    width: '100%',
    height: '40%'
  },

  index3: {
    transform: 'rotate(90deg) translateX(calc(-50% - 7em))',
    transformOrigin: '0 100% 0'
  },
  index3Portrait: {
    height: '17%'
  },
  index3PortraitFlipped: {
    marginLeft: '-11em'
  }
}));

export default function CardList (props) {
  const {
    index,
    cards,
    flipped,
    enabledSuit,
    isStack,
    contract,
    onCardClick,
    cardRefCallback
  } = props;

  const cardVariant = useCardVariant();
  const componentScale = useComponentScale();
  const isPortrait = useOrientation();
  
  let scale = componentScale / 0.95;

  if (flipped) {
    scale = componentScale / 1.25;
  }

  if (isPortrait) {
    scale = componentScale / 0.75;
    if (flipped) {
      scale = componentScale / 1.25;
    }
  }

  const suits = getOrderOfSuitsForCardList(contract, index);

  const classes = useStyles();
  const seatIndex = index;
  return (
    <div
      className={
      clsx(
        classes.container,
        classes[`index${index}`],
        isStack
          ? (
              classes[`index${index}Stack`],
              isPortrait
                ? (
                    classes.isSpread
                  )
                : (
                    seatIndex !== 0 && classes.isLeftOrRightStack
                  )
            )
          : classes.isSpread,
        isPortrait && (
          flipped
            ? classes[`index${index}PortraitFlipped`]
            : classes[`index${index}Portrait`]
        )
      )
}
      style={{ fontSize: `${scale}rem` }}
    >
      {
      suits.map((suit, index) => {
        const suitCards = getCardsForSuit(cards, suit);
        return (
          <CardSuitGroup
            key={index}
            seatIndex={seatIndex}
            cards={suitCards}
            flipped={flipped}
            isStack={isStack}
            enabledSuit={enabledSuit}
            enabled={suit === enabledSuit || enabledSuit === 'all'}
            variant={cardVariant}
            onCardClick={onCardClick}
            cardRefCallback={cardRefCallback}
          />
        );
      })
    }
    </div>
  );
};

CardList.propTypes = {
  index: PropTypes.number.isRequired,
  cards: PropTypes.array.isRequired,
  flipped: PropTypes.bool.isRequired,
  isStack: PropTypes.bool.isRequired,
  enabledSuit: PropTypes.string.isRequired,
  contract: PropTypes.object.isRequired,
  onCardClick: PropTypes.func,
  cardRefCallback: PropTypes.func
};
