import React from 'react';
import { makeStyles } from '@mui/styles';
import SnackbarContent from '@mui/material/SnackbarContent';
import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { amber, green } from '@mui/material/colors';

import { BBSnackbarConsumer } from './BBSnackbarContext.js';

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20,
    opacity: 0.9
    // marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const SnackBarIcon = (props) => {
  switch (props.messageType) {
    case 'success':
      return (
        <CheckCircleIcon className={props.className} />
      );
    case 'error':
      return (
        <ErrorIcon className={props.className} />
      );
    case 'warning':
      return (
        <WarningIcon className={props.className} />
      );
    case 'info':
      return (
        <InfoIcon className={props.className} />
      );
    default:
      return null;
  }
};

const BBSnackbar = () => {
  const classes = useStyles();
  return (
    <BBSnackbarConsumer>
      {({ isOpen, message, type, closeSnackbar }) => {
        return (
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={isOpen}
            autoHideDuration={3000}
            onClose={closeSnackbar}
          >
            <SnackbarContent
              className={classes[type]}
              aria-describedby='client-snackbar'
              message={
                <span id='client-snackbar' className={classes.message}>
                  <SnackBarIcon
                    messageType={type}
                    className={classes.icon}
                  />
                  &nbsp;{message}
                </span>
              }
              action={[
                <IconButton
                  key='close'
                  aria-label='close'
                  color='inherit'
                  onClick={closeSnackbar}
                  size='large'
                >
                  <CloseIcon />
                </IconButton>
              ]}
            />
          </Snackbar>
        );
      }}
    </BBSnackbarConsumer>
  );
};

export default BBSnackbar;
