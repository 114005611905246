import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import IconButton from './MobileMenu/IconButton.js';
import BackToSetIcon from '@mui/icons-material/ListRounded';
import ReplayHandIcon from '@mui/icons-material/ReplayRounded';
import PlayNextHandIcon from '@mui/icons-material/ArrowForwardRounded';
import StoreIcon from '@mui/icons-material/StorefrontRounded';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCartOutlined';
import { 
  CartContext,
  DrawerContext,
  HandContext
} from '../../../../Context';
import BBTranslate from '../../../../Components/Locale/BBTranslate.js';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0.7rem 0.4rem'
  },
  gridItem: {
    padding: '0 0.3rem'
  },
  icon: {
    fontSize: 30
  },
  buyButton: {
    backgroundColor: '#FFB73A'
  },
  removeFromCartButton: {
    backgroundColor: theme.palette.styleguide.red
  }
}));

export default function CompleteButtons () {
  const classes = useStyles();

  const {
    handleReturnToParent,
    handleBrowseStore,
    handleRestartHand,
    handlePlayNext,
    nextHandUrl,
    grandParent,
    parent,
    isEmbed,
    getIsOnSale,
    hand,
    purchaseType,
    isPreview
  } = useContext(HandContext);

  const {
    addProductToCart,
    removeFromCart,
    checkIfInCart
  } = useContext(CartContext);

  const { openDrawer } = useContext(DrawerContext);

  const handleAddToCartClick = () => {
    const productToBuy = grandParent ? grandParent : parent;
    addProductToCart(productToBuy);
    openDrawer('cart');
  };

  const handleRemoveFromCartClick = () => {
    const productToRemove = grandParent ? grandParent : parent;
    removeFromCart(productToRemove.id);
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='flex-start'
      >
        {
          !isEmbed && handleReturnToParent
            ? <Grid item xs className={classes.gridItem}>
              <IconButton
                icon={<BackToSetIcon className={classes.icon} />}
                onClick={handleReturnToParent}
              >
                <BBTranslate textId={purchaseType === 'product' ? 'return-to-product-details' : 'return-to-article-details'} />
              </IconButton>
            </Grid>
            : null
        }
        {
          !isPreview && (
            <>
            {
              hand.canPlay
                ? <>
                  <Grid item xs className={classes.gridItem}>
                    <IconButton
                      icon={<ReplayHandIcon className={classes.icon} />}
                      onClick={handleRestartHand}
                      size='large'
                    >
                      <BBTranslate textId='replay-hand' />
                    </IconButton>
                  </Grid>
                  {
                    nextHandUrl && (
                      <Grid item xs className={classes.gridItem}>
                        <IconButton
                          icon={<PlayNextHandIcon className={classes.icon} />}
                          onClick={handlePlayNext}
                          size='large'
                        >
                          <BBTranslate textId='play-next-hand' />
                        </IconButton>
                      </Grid>
                    )
                }
                </>
                : <>
                  {
                    handleBrowseStore
                      ? <Grid item xs className={classes.gridItem}>
                        <IconButton
                          icon={<StoreIcon className={classes.icon} />}
                          onClick={handleBrowseStore}
                        >
                          <BBTranslate textId='browse-products' />
                        </IconButton>
                      </Grid>
                      : null
                  }              
                  {
                  getIsOnSale()
                    && (
                      <Grid item xs className={classes.gridItem}>
                        {
                          checkIfInCart()
                            ? (
                              <IconButton
                                icon={<RemoveShoppingCartIcon className={classes.icon} />}
                                onClick={handleRemoveFromCartClick}
                                className={classes.removeFromCartButton}
                                size='large'
                              >
                                <BBTranslate textId='remove-from-cart' />
                              </IconButton>
                            )
                            : (
                              <IconButton
                                icon={<AddShoppingCartIcon className={classes.icon} />}
                                onClick={handleAddToCartClick}
                                className={classes.buyButton}
                                size='large'
                              >
                                <BBTranslate textId='add-to-cart' />
                              </IconButton>
                            )
                        }
                      </Grid>
                    )
                }
                </>
            }
            </>
          )
        }
      </Grid>
    </div>
  );
};